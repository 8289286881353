export function toRecord<E, K extends string | number | symbol, V>(
  es: E[],
  toKey: (e: E) => K,
  toValue: (e: E) => V
): Record<K, V> {
  const record = {} as Record<K, V>
  es.forEach(e => {
    record[toKey(e)] = toValue(e)
  })
  return record
}

export function entries<K extends string | number | symbol, V>(o: Record<K, V>): Array<[K, V]> {
  return Object.entries(o) as Array<[K, V]>
}

export function keys<O extends Record<string, unknown>>(o: O): Array<keyof O> {
  return Object.keys(o) as Array<keyof O>
}

export function values<O extends Record<string, unknown>>(o: O): Array<O[keyof O]> {
  return Object.values(o) as Array<O[keyof O]>
}
