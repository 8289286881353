import * as React from 'react'

import classnames from 'classnames'

import { ClassValue } from '../../../utils/classnames'

interface IButtonTheme {
  button?: ClassValue
  disabled?: ClassValue
}

interface IButtonProps {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => void
  theme?: IButtonTheme
}

export const Button = (props: IButtonProps) => {
  const theme = props.theme || {}
  return (
    <button
      disabled={props.disabled}
      className={classnames(theme.button, props.disabled ? theme.disabled : null)}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
