import { weeksToHours } from './utils/DateTime/weeksToHours'

export const DATE_RANGE_START_BASE_OPTIONS = [
  { value: 0, label: 'Current time' },
  { value: -12, label: '- 12H' },
]

export const DATE_RANGE_OFFSET_BASE_OPTIONS = [{ value: 12, label: '+ 12H' }]

export const DATE_RANGE_START_OPTIONS = [
  ...DATE_RANGE_START_BASE_OPTIONS,
  { value: -24, label: '- 24H' },
  { value: -48, label: '- 48H' },
  { value: -72, label: '- 72H' },
  { value: weeksToHours(-1), label: '- 1W' },
  { value: weeksToHours(-2), label: '- 2W' },
  { value: weeksToHours(-3), label: '- 3W' },
]

export const DATE_RANGE_OFFSET_OPTIONS = [
  ...DATE_RANGE_OFFSET_BASE_OPTIONS,
  { value: 24, label: '+ 24H' },
  { value: 48, label: '+ 48H' },
  { value: 72, label: '+ 72H' },
  { value: weeksToHours(1), label: '+ 1W' },
  { value: weeksToHours(2), label: '+ 2W' },
  { value: weeksToHours(4), label: '+ 4W' },
  { value: weeksToHours(6), label: '+ 6W' },
]

export enum Key {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Escape = 'Escape',
}
