import { useState } from 'react'

import classnames from 'classnames'

import { UserId } from '../../../../ApplicationState/UsersManagement/User'
import { EnumOps } from '../../../../utils/Enum'
import { compose } from '../../../../utils/functions'
import { TestIds } from '../../../../utils/testIds/testIds'
import { UnionOf, unionOfOps } from '../../../../utils/UnionOf'
import {
  WhatToConfirm,
  confirmActivateView,
  confirmDeactivateView,
  confirmDeleteView,
} from '../../../../View/UsersManagement/Confirm/ConfirmView'
import { GroupView } from '../../../../View/UsersManagement/GroupView'
import { LocaleView } from '../../../../View/UsersManagement/LocaleView'
import { PortView } from '../../../../View/UsersManagement/PortView'
import { RoleView } from '../../../../View/UsersManagement/RoleView'
import { EditUserView } from '../../../../View/UsersManagement/UsersView'
import { modalLayoutColumns, modalLayoutOneColumn } from '../../../Modal/Layout/ModalLayout'
import { Modal } from '../../../Modal/Modal'
import { Confirm } from '../Confirm/Confirm'
import { UserManagementModalForm } from '../Form/UserManagementModalForm'
import { UserManagementModalSidePanel } from '../SidePanel/UserManagementModalSidePanel'

import styles from './UserManagementModalEditUser.module.scss'

type UserManagementModalEditUserProps = Readonly<{
  user: EditUserView
  roles: RoleView[]
  groups: GroupView[]
  ports: PortView[]
  locales: LocaleView[]
  onSave: (user: EditUserView) => void
  onDelete: (userId: UserId) => void
  onClose: () => void
}>

type ModalStateCases = Readonly<{
  form(): void
  confirm(type: WhatToConfirm): void
}>

type ModalState = UnionOf<ModalStateCases>
const modalState = unionOfOps<ModalStateCases>().withConstructors('form', 'confirm')

export const UserManagementModalEditUser = (props: UserManagementModalEditUserProps) => {
  const [state, setState] = useState<ModalState>(() => modalState.form())
  const setForm = compose(modalState.form, setState)
  const setConfirm = compose(modalState.confirm, setState)

  return modalState.match(state, {
    form() {
      return (
        <Modal onClose={props.onClose} layout={modalLayoutColumns}>
          <div className={classnames(styles.column, styles.form)}>
            <UserManagementModalForm
              title="Adjust user account"
              roles={props.roles}
              groups={props.groups}
              ports={props.ports}
              user={props.user}
              onSave={props.onSave}
              locales={props.locales}
            />
          </div>
          <div className={classnames(styles.column, styles.sidePanel)}>
            <UserManagementModalSidePanel
              userId={props.user.id}
              isBlocked={props.user.blocked}
              onToggleBlocked={() => {
                setConfirm(props.user.blocked ? WhatToConfirm.ActivateAccount : WhatToConfirm.DeactivateAccount)
              }}
              onDeleteUser={() => {
                setConfirm(WhatToConfirm.Delete)
              }}
            />
          </div>
        </Modal>
      )
    },
    confirm(type) {
      return (
        <Modal onClose={props.onClose} layout={modalLayoutOneColumn}>
          {EnumOps.match(type, {
            [WhatToConfirm.ActivateAccount]: (
              <Confirm
                {...confirmActivateView}
                onCancel={setForm}
                onConfirm={() => {
                  props.onSave({
                    ...props.user,
                    blocked: false,
                  })
                }}
              />
            ),
            [WhatToConfirm.DeactivateAccount]: (
              <Confirm
                {...confirmDeactivateView}
                onCancel={setForm}
                onConfirm={() => {
                  props.onSave({
                    ...props.user,
                    blocked: true,
                  })
                }}
              />
            ),
            [WhatToConfirm.Delete]: (
              <Confirm
                {...confirmDeleteView}
                onCancel={setForm}
                onConfirm={() => props.onDelete(props.user.id)}
                testId={TestIds.UserManagementModalFormConfirmDeletionButton}
              />
            ),
          })}
        </Modal>
      )
    },
  })
}
