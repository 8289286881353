import * as React from 'react'

import HamburgerIcon from '../../Icons/HamburgerIcon/HamburgerIcon'

import styles from './PrimaryMenu.module.scss'

interface IPrimaryMenuProps {
  toggleHamburger: () => void
}

const timeoutForRippleEffect = 200

export class PrimaryMenu extends React.PureComponent<IPrimaryMenuProps> {
  public render() {
    const { toggleHamburger } = this.props

    function onClick() {
      // Give the ripple effect some time to animate:
      setTimeout(toggleHamburger, timeoutForRippleEffect)
    }

    return (
      <div className={styles.primaryMenu}>
        <div className={styles.left}>
          <div className={styles.hamburger} onClick={onClick}>
            <HamburgerIcon />
          </div>
        </div>
      </div>
    )
  }
}
