export const Logo = () => (
  <svg width="131px" height="47px" viewBox="0 0 131 47" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="0.00150651028%" y1="49.9995696%" x2="100.000646%" y2="49.9995696%" id="linearGradient-1">
        <stop stop-color="#1D1F43" offset="0%"></stop>
        <stop stop-color="#23388D" offset="49.08%"></stop>
        <stop stop-color="#23388D" offset="100%"></stop>
      </linearGradient>
      <rect id="path-2" x="0" y="0" width="10.6666667" height="10.6666667"></rect>
      <rect id="path-4" x="0" y="0" width="10.6666667" height="10.6666667"></rect>
      <linearGradient x1="34.3721933%" y1="49.9999522%" x2="66.7115614%" y2="50%" id="linearGradient-6">
        <stop stop-color="#1D1F43" offset="0%"></stop>
        <stop stop-color="#23388D" offset="49.08%"></stop>
        <stop stop-color="#23388D" offset="100%"></stop>
      </linearGradient>
      <rect id="path-7" x="0" y="0" width="10.6666667" height="10.6666667"></rect>
      <rect id="path-9" x="0" y="0" width="10.6666667" height="10.6666667"></rect>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="A1" transform="translate(-819, -1409)">
        <g id="LOGO_NEW-Copy-41" transform="translate(819.9645, 1409.9645)">
          <g
            id="final-copy"
            transform="translate(22.6274, 22.6274) rotate(-45) translate(-22.6274, -22.6274)translate(6.6274, 6.6274)"
          >
            <g id="Group-2-Copy" transform="translate(0, 10.6667)">
              <rect
                id="Rectangle"
                fill="url(#linearGradient-1)"
                fill-rule="nonzero"
                x="0"
                y="0"
                width="32"
                height="10.6666667"
              ></rect>
              <g id="Rectangle-Copy-2">
                <mask id="mask-3" fill="white">
                  <use xlinkHref="#path-2"></use>
                </mask>
                <g id="Mask" fill-rule="nonzero"></g>
                <rect
                  fill="#035CA2"
                  fill-rule="nonzero"
                  mask="url(#mask-3)"
                  x="0"
                  y="0"
                  width="32"
                  height="10.6666667"
                ></rect>
              </g>
              <g id="Rectangle-Copy-3" transform="translate(21.3333, 0)">
                <mask id="mask-5" fill="white">
                  <use xlinkHref="#path-4"></use>
                </mask>
                <g id="Mask"></g>
                <rect
                  fill="#4A378C"
                  fill-rule="nonzero"
                  mask="url(#mask-5)"
                  x="-21.3333333"
                  y="0"
                  width="32"
                  height="10.6666667"
                ></rect>
              </g>
            </g>
            <g id="Group-2-Copy-2" transform="translate(16, 16) rotate(90) translate(-16, -16)translate(0, 10.6667)">
              <rect
                id="Rectangle"
                fill="url(#linearGradient-6)"
                fill-rule="nonzero"
                x="0"
                y="0"
                width="32"
                height="10.6666667"
              ></rect>
              <g id="Rectangle-Copy-2">
                <mask id="mask-8" fill="white">
                  <use xlinkHref="#path-7"></use>
                </mask>
                <g id="Mask" fill-rule="nonzero"></g>
                <rect
                  fill="#127834"
                  fill-rule="nonzero"
                  mask="url(#mask-8)"
                  x="0"
                  y="0"
                  width="32"
                  height="10.6666667"
                ></rect>
              </g>
              <g id="Rectangle-Copy-3" transform="translate(21.3333, 0)">
                <mask id="mask-10" fill="white">
                  <use xlinkHref="#path-9"></use>
                </mask>
                <g id="Mask"></g>
                <rect
                  fill="#44B4E7"
                  fill-rule="nonzero"
                  mask="url(#mask-10)"
                  x="-21.3333333"
                  y="0"
                  width="32"
                  height="10.6666667"
                ></rect>
              </g>
            </g>
          </g>
          <g id="User-Admin" transform="translate(45.2195, 16.0355)" fill="#002255" fill-rule="nonzero">
            <path
              d="M4.496,12.272 C3.67466667,12.272 2.92,12.08 2.232,11.696 C1.544,11.312 1,10.792 0.6,10.136 C0.2,9.48 0,8.75733333 0,7.968 L0,0.816 L2.512,0.816 L2.512,7.952 C2.512,8.50666667 2.704,8.976 3.088,9.36 C3.472,9.744 3.94133333,9.936 4.496,9.936 C5.02933333,9.936 5.48533333,9.744 5.864,9.36 C6.24266667,8.976 6.432,8.50666667 6.432,7.952 L6.432,0.816 L8.96,0.816 L8.96,7.968 C8.96,8.75733333 8.76,9.48 8.36,10.136 C7.96,10.792 7.41866667,11.312 6.736,11.696 C6.05333333,12.08 5.30666667,12.272 4.496,12.272 Z"
              id="Path"
            ></path>
            <path
              d="M11.664,9.504 C11.856,9.70666667 12.1173333,9.89066667 12.448,10.056 C12.7786667,10.2213333 13.1253333,10.304 13.488,10.304 C13.776,10.304 13.9973333,10.24 14.152,10.112 C14.3066667,9.984 14.384,9.824 14.384,9.632 C14.384,9.46133333 14.3093333,9.32 14.16,9.208 C14.0106667,9.096 13.792,9.008 13.504,8.944 L12.864,8.816 C12.1386667,8.64533333 11.584,8.344 11.2,7.912 C10.816,7.48 10.624,6.95466667 10.624,6.336 C10.624,5.856 10.76,5.41866667 11.032,5.024 C11.304,4.62933333 11.672,4.31733333 12.136,4.088 C12.6,3.85866667 13.0986667,3.744 13.632,3.744 C14.8373333,3.744 15.8293333,4.13333333 16.608,4.912 L15.28,6.352 C15.088,6.14933333 14.8586667,5.98933333 14.592,5.872 C14.3253333,5.75466667 14.0373333,5.696 13.728,5.696 C13.472,5.696 13.2666667,5.75466667 13.112,5.872 C12.9573333,5.98933333 12.88,6.128 12.88,6.288 C12.88,6.448 12.9466667,6.57066667 13.08,6.656 C13.2133333,6.74133333 13.4293333,6.816 13.728,6.88 L14.304,7.008 C15.008,7.136 15.576,7.42133333 16.008,7.864 C16.44,8.30666667 16.656,8.85866667 16.656,9.52 C16.656,10.0426667 16.5173333,10.5146667 16.24,10.936 C15.9626667,11.3573333 15.5866667,11.688 15.112,11.928 C14.6373333,12.168 14.112,12.288 13.536,12.288 C12.832,12.288 12.208,12.1706667 11.664,11.936 C11.12,11.7013333 10.6773333,11.408 10.336,11.056 L11.664,9.504 Z"
              id="Path"
            ></path>
            <path
              d="M25.92,7.856 C25.92,8.144 25.8933333,8.42133333 25.84,8.688 L19.792,8.688 C19.9413333,9.15733333 20.208,9.528 20.592,9.8 C20.976,10.072 21.4346667,10.208 21.968,10.208 C22.7786667,10.208 23.4506667,9.92 23.984,9.344 L25.376,10.896 C24.4586667,11.824 23.28,12.288 21.84,12.288 C21.0293333,12.288 20.288,12.1066667 19.616,11.744 C18.944,11.3813333 18.4133333,10.8746667 18.024,10.224 C17.6346667,9.57333333 17.44,8.848 17.44,8.048 C17.44,7.25866667 17.6266667,6.536 18,5.88 C18.3733333,5.224 18.8826667,4.704 19.528,4.32 C20.1733333,3.936 20.8906667,3.744 21.68,3.744 C22.4906667,3.744 23.2186667,3.92266667 23.864,4.28 C24.5093333,4.63733333 25.0133333,5.13066667 25.376,5.76 C25.7386667,6.38933333 25.92,7.088 25.92,7.856 Z M21.696,5.712 C21.2266667,5.712 20.8293333,5.83466667 20.504,6.08 C20.1786667,6.32533333 19.9466667,6.66666667 19.808,7.104 L23.616,7.104 C23.4773333,6.67733333 23.2373333,6.33866667 22.896,6.088 C22.5546667,5.83733333 22.1546667,5.712 21.696,5.712 Z"
              id="Shape"
            ></path>
            <path
              d="M32.016,3.888 L32.016,6.272 L32,6.272 C31.552,6.272 31.1466667,6.376 30.784,6.584 C30.4213333,6.792 30.1386667,7.06933333 29.936,7.416 C29.7333333,7.76266667 29.632,8.144 29.632,8.56 L29.632,12 L27.168,12 L27.168,4.032 L29.488,4.032 L29.488,5.6 C29.936,4.45866667 30.7733333,3.888 32,3.888 L32.016,3.888 Z"
              id="Path"
            ></path>
            <path
              d="M43.904,9.68 L39.52,9.68 L38.672,12 L36.192,12 L40.4,0.816 L43.184,0.816 L47.408,12 L44.736,12 L43.904,9.68 Z M43.088,7.376 L41.728,3.568 L40.352,7.376 L43.088,7.376 Z"
              id="Shape"
            ></path>
            <path
              d="M56.592,0 L56.592,12 L54.272,12 L54.272,10.896 C53.632,11.76 52.7786667,12.192 51.712,12.192 C50.976,12.192 50.3173333,12.008 49.736,11.64 C49.1546667,11.272 48.704,10.768 48.384,10.128 C48.064,9.488 47.904,8.784 47.904,8.016 C47.904,7.248 48.064,6.544 48.384,5.904 C48.704,5.264 49.1546667,4.76 49.736,4.392 C50.3173333,4.024 50.976,3.84 51.712,3.84 C52.6933333,3.84 53.4986667,4.21333333 54.128,4.96 L54.128,0 L56.592,0 Z M52.336,10.016 C52.8906667,10.016 53.352,9.82666667 53.72,9.448 C54.088,9.06933333 54.272,8.592 54.272,8.016 C54.272,7.45066667 54.088,6.97866667 53.72,6.6 C53.352,6.22133333 52.8906667,6.032 52.336,6.032 C51.7706667,6.032 51.3013333,6.22133333 50.928,6.6 C50.5546667,6.97866667 50.368,7.45066667 50.368,8.016 C50.368,8.592 50.5546667,9.06933333 50.928,9.448 C51.3013333,9.82666667 51.7706667,10.016 52.336,10.016 Z"
              id="Shape"
            ></path>
            <path
              d="M68.144,3.856 C68.6773333,3.856 69.1546667,3.984 69.576,4.24 C69.9973333,4.496 70.3306667,4.856 70.576,5.32 C70.8213333,5.784 70.944,6.30933333 70.944,6.896 L70.944,12 L68.48,12 L68.48,7.552 C68.48,7.08266667 68.36,6.71733333 68.12,6.456 C67.88,6.19466667 67.5786667,6.064 67.216,6.064 C66.8426667,6.064 66.5306667,6.19733333 66.28,6.464 C66.0293333,6.73066667 65.904,7.104 65.904,7.584 L65.904,12 L63.456,12 L63.456,7.552 C63.456,7.09333333 63.3333333,6.73066667 63.088,6.464 C62.8426667,6.19733333 62.5386667,6.064 62.176,6.064 C61.8026667,6.064 61.4933333,6.19733333 61.248,6.464 C61.0026667,6.73066667 60.88,7.104 60.88,7.584 L60.88,12 L58.416,12 L58.416,4.032 L60.736,4.032 L60.736,5.328 C60.9706667,4.83733333 61.312,4.46933333 61.76,4.224 C62.208,3.97866667 62.672,3.856 63.152,3.856 C63.728,3.856 64.224,3.98666667 64.64,4.248 C65.056,4.50933333 65.376,4.88 65.6,5.36 C65.824,4.912 66.152,4.54933333 66.584,4.272 C67.016,3.99466667 67.536,3.856 68.144,3.856 Z"
              id="Path"
            ></path>
            <path
              d="M72.752,4.032 L75.216,4.032 L75.216,12 L72.752,12 L72.752,4.032 Z M74,2.8 C73.6266667,2.8 73.2986667,2.672 73.016,2.416 C72.7333333,2.16 72.592,1.84 72.592,1.456 C72.592,1.072 72.7306667,0.754666667 73.008,0.504 C73.2853333,0.253333333 73.616,0.128 74,0.128 C74.3733333,0.128 74.6986667,0.253333333 74.976,0.504 C75.2533333,0.754666667 75.392,1.072 75.392,1.456 C75.392,1.84 75.2533333,2.16 74.976,2.416 C74.6986667,2.672 74.3733333,2.8 74,2.8 Z"
              id="Shape"
            ></path>
            <path
              d="M81.824,3.856 C82.3893333,3.856 82.8986667,3.992 83.352,4.264 C83.8053333,4.536 84.1626667,4.91466667 84.424,5.4 C84.6853333,5.88533333 84.816,6.43733333 84.816,7.056 L84.816,12 L82.368,12 L82.368,7.616 C82.368,7.14666667 82.2293333,6.776 81.952,6.504 C81.6746667,6.232 81.3333333,6.096 80.928,6.096 C80.5226667,6.096 80.184,6.23466667 79.912,6.512 C79.64,6.78933333 79.504,7.15733333 79.504,7.616 L79.504,12 L77.04,12 L77.04,4.032 L79.36,4.032 L79.36,5.344 C79.584,4.88533333 79.9093333,4.52266667 80.336,4.256 C80.7626667,3.98933333 81.2586667,3.856 81.824,3.856 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
