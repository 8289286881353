import { ILoaderState, LOADING } from '../../../store/ui/interfaces/ILoaderState'
import { Loader, LoaderTheme } from '../Loader'

import styles from './FullLoadingLoader.module.scss'

const loaderState: ILoaderState = {
  type: LOADING,
}

type FullLoadingLoaderProps = Readonly<{
  theme?: LoaderTheme
}>

export const FullLoadingLoader = (props: FullLoadingLoaderProps) => {
  return <Loader state={loaderState} loaderVisible containerClassName={styles.loader} theme={props.theme} />
}
