import { ErrorMessage } from 'formik'

import { Path } from '../../../../utils/Path'
import { TestIds } from '../../../../utils/testIds/testIds'
import { FormField } from '../../../FormField/FormField'
import { StandardizedOption } from '../../../MultiSelectDropdown/MultiSelectDropdown'
import { StandardizedSelectDropdown } from '../../../SelectDropdown/SelectDropdown'
import { ModalFormItem } from '../Item/ModalFormItem'

type ModalSelectFieldProps<P, Id extends string | number, Option extends StandardizedOption<Id>> = Readonly<{
  label: string
  name: Path<P, Id>
  options: Option[]
  testId?: TestIds
}>

export function ModalSelectField<P, Id extends string | number, Option extends StandardizedOption<Id>>(
  props: ModalSelectFieldProps<P, Id, Option>
) {
  return (
    <FormField name={props.name}>
      {({ field, form }) => {
        return (
          <ModalFormItem
            label={props.label}
            testId={props.testId}
            feedback={<ErrorMessage name={field.name} />}
            name={field.name}
          >
            {id => (
              <StandardizedSelectDropdown<Id, Option>
                id={id}
                selectedId={field.value}
                onChange={selectedGroupId => {
                  form.setFieldValue(field.name, selectedGroupId, true)
                  form.setFieldTouched(field.name, true, false)
                }}
                options={props.options}
                filterFn={(option, query) => option.name?.toLowerCase().includes(query.toLowerCase())}
              />
            )}
          </ModalFormItem>
        )
      }}
    </FormField>
  )
}
