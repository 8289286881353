import { CompanyId } from '../../ApplicationState/Company/Company'
import { UserId } from '../../ApplicationState/UsersManagement/User'
import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { getAuthToken } from '../../utils/auth/authClientObservable'
import { none } from '../../utils/strictNull'

export const deleteUser = async (userId: UserId, companyId: CompanyId): Promise<boolean> => {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/${companyId}/users/${encodeURIComponent(userId)}`

  const options = {
    method: 'DELETE',
    headers: createHeaders(authToken),
  }

  return fetch(url, options)
    .then(response => {
      if (response.status === 204) {
        return Promise.resolve(none)
      }
      return handleJSONResponse(options, response)
    })
    .then(
      () => true,
      () => false
    )
}
