export const notFoundStatusCode = 404 as const

export type NotFoundStatusCode = typeof notFoundStatusCode

export const NotFound = {
  failWith<A>(promise: Promise<A | NotFoundStatusCode>, failure: any): Promise<A> {
    return promise.then(valueOrNotFound => {
      if (valueOrNotFound === notFoundStatusCode) {
        return Promise.reject(failure)
      }
      return Promise.resolve(valueOrNotFound)
    })
  },
  map<A, B>(a: A | NotFoundStatusCode, fn: (a: A) => B): B | NotFoundStatusCode {
    if (a === notFoundStatusCode) {
      return notFoundStatusCode
    }

    return fn(a)
  },
  fold<A, B, Alternative>(a: A | NotFoundStatusCode, fn: (a: A) => B, alternative: Alternative): B | Alternative {
    return NotFound.orElse(NotFound.map(a, fn), alternative)
  },
  lift<A, B>(fn: (a: A) => B): (a: A | NotFoundStatusCode) => B | NotFoundStatusCode {
    return (a: A | NotFoundStatusCode) => NotFound.map(a, fn)
  },
  orElse<A, B>(a: A | NotFoundStatusCode, alternative: B): A | B {
    return a === notFoundStatusCode ? alternative : a
  },
}
