import classnames from 'classnames'

import { throwUnreachable } from '../../../utils/throwUnreachable'
import { InlineLoader, yellowBackgroundInlineLoader } from '../../Loader/InlineLoader'
import { Button } from '../SideSubMenuButtons/SideSubMenuButtons'
import {
  SideSubMenuFormState,
  FORM_STATE_INITIAL,
  FORM_STATE_SUBMITTING,
  FORM_STATE_SUBMITTED,
  FORM_STATE_SUBMIT_ERROR,
} from '../SideSubMenuForm/SideSubMenuFormState'

import styles from './SideSubMenuSubmitButton.module.scss'

type SideSubMenuSubmitButtonProps = Readonly<{
  onSubmit: () => void
  formState: SideSubMenuFormState
  isFormValid: boolean
}>

export const SideSubMenuSubmitButton = ({ onSubmit, formState, isFormValid }: SideSubMenuSubmitButtonProps) => {
  switch (formState) {
    case FORM_STATE_INITIAL:
      return (
        <Button
          disabled={!isFormValid}
          onClick={onSubmit}
          theme={{
            button: classnames(styles.submitButton, styles.clickable, {
              [styles.active]: isFormValid,
              [styles.disabled]: !isFormValid,
            }),
          }}
        >
          <div className={styles.text}>Send</div>
        </Button>
      )
    case FORM_STATE_SUBMITTING:
      return (
        <Button
          disabled
          onClick={onSubmit}
          theme={{
            button: classnames(styles.submitButton, styles.active),
          }}
        >
          <div className={styles.text}>Submitting</div>
          <InlineLoader theme={yellowBackgroundInlineLoader} />
        </Button>
      )
    case FORM_STATE_SUBMITTED:
      return (
        <Button
          disabled
          onClick={onSubmit}
          theme={{
            button: classnames(styles.submitButton, styles.success),
          }}
        >
          <div className={styles.text}>Sent</div>
          <div className={styles.submittedIcon} />
        </Button>
      )
    case FORM_STATE_SUBMIT_ERROR:
      return (
        <Button
          disabled={false}
          onClick={onSubmit}
          theme={{
            button: classnames(styles.submitButton, styles.active),
          }}
        >
          <div className={styles.text}>Try again</div>
        </Button>
      )
    default:
      return throwUnreachable(formState)
  }
}
