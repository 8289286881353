import styles from './Button.module.scss'

export enum ButtonRole {
  Primary,
  Secondary,
  Tertiary,
  Quaternary,
}

export const stylesByButtonRole: Record<ButtonRole, string> = {
  [ButtonRole.Primary]: styles.primary,
  [ButtonRole.Secondary]: styles.secondary,
  [ButtonRole.Tertiary]: styles.tertiary,
  [ButtonRole.Quaternary]: styles.quaternary,
}
