import { PRONTO_BACKEND_URL } from '../config'

export const BACKEND_URL = PRONTO_BACKEND_URL
export const SECOND = 1000 // second in milliseconds
export const MINUTE = 60 * SECOND // minute in milliseconds
export const HOUR = 60 * MINUTE // hour in milliseconds
export const DAY = 24 * HOUR // day in milliseconds
export const WEEK = 7 * DAY // week in milliseconds
export const MONTH = 30 * DAY // week in milliseconds
export const YEAR = 365 * DAY // week in milliseconds
