export type Column<Id extends string | number, Value, Node, AdditionalProps> = Readonly<{
  id: Id
  header: string
  trackingCategory?: string
  getValue(value: Value, additionalProps: AdditionalProps): Node
}>

export function createColumn<Id extends string | number, Outer, Inner, Node, AdditionalProps>(
  id: Id,
  header: string,
  accessor: (outer: Outer) => Inner,
  formatter: (inner: Inner, outer: Outer, additionalProps: AdditionalProps) => Node,
  trackingCategory?: string
): Column<Id, Outer, Node, AdditionalProps> {
  return {
    id,
    header,
    getValue(outer: Outer, additionalProps: AdditionalProps): Node {
      return formatter(accessor(outer), outer, additionalProps)
    },
    trackingCategory,
  }
}

export function createEmptyColumn<Id extends string | number>(id: Id): Column<Id, never, null, never> {
  return {
    id,
    header: '',
    getValue() {
      return null
    },
  }
}
