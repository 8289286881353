import { groupBy as groupBySingle, flatten, first as _first, last as _last, nth as _nth } from 'lodash'

import { values } from './obj'
import { notFalse, Predicate, TypeGuard } from './predicates'
import { None, none } from './strictNull'

export function groupBy<A>(
  arr: A[],
  ...propertyGetters: Array<(a: A) => string | number | boolean | null | undefined>
): A[][] {
  if (propertyGetters.length === 0) {
    return [arr]
  }

  const [firstPropertyGetter, ...otherPropertyGetters] = propertyGetters
  const grouped = values(groupBySingle(arr, firstPropertyGetter))
  return flatten(grouped.map(group => groupBy(group, ...otherPropertyGetters)))
}

export function includes<A extends B, B>(haystack: A[], needle: B): needle is A {
  return haystack.includes(needle as A)
}

// `Array#sort` is not stable (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
// The implementation below is, because it uses the index of the elements to stabilize the sorting.
export function stableSort<A>(arr: A[], comparator: (left: A, right: A) => number): A[] {
  return arr
    .map((item, index): [A, number] => [item, index])
    .sort(([left, leftIndex], [right, rightIndex]) => {
      const compared = comparator(left, right)
      return compared === 0 ? leftIndex - rightIndex : compared
    })
    .map(([elem]) => elem)
}

export function filterMap<A, B>(arr: A[], fn: (a: A, i: number) => B | None): B[] {
  return arr.map(fn).filter(notFalse)
}

export const lift =
  <A, B>(fn: (a: A) => B) =>
  (ar: A[]): B[] => {
    return ar.map(fn)
  }

export const empty: never[] = []

export function filter<A, B extends A>(predicate: TypeGuard<A, B>): (arr: A[]) => B[]
export function filter<A>(predicate: Predicate<A>): (arr: A[]) => A[]
export function filter<A>(predicate: Predicate<A> | TypeGuard<A, any>) {
  return (arr: A[]) => arr.filter(predicate)
}

export function first<A>(arr: A[]): A | None {
  return _first(arr) || none
}

export function last<A>(arr: A[]): A | None {
  return _last(arr) || none
}

export function nth<A>(arr: A[], n?: number): A | None {
  return _nth(arr, n) || none
}
