import classnames from 'classnames'

import styles from './Toggle.module.scss'

const TOGGLE_THEME_DEFAULT: ToggleTheme = {
  toggle: styles.toggleThemeDefault,
  toggleLabel: styles.toggleLabel,
  toggleContainer: styles.toggleContainer,
  disabled: styles.disabled,
  toggleDot: styles.toggleDot,
  toggleText: styles.toggleText,
}

export type ToggleTheme = Readonly<{
  toggle: string
  toggleLabel: string
  toggleContainer: string
  disabled: string
  toggleDot: string
  toggleText: string
}>

interface IToggleProps {
  isChecked: boolean
  onToggle: (checked: boolean) => void
  disabled?: boolean
  showLabel?: boolean
  label?: string
  theme?: ToggleTheme
}

export const Toggle = ({ theme = TOGGLE_THEME_DEFAULT, ...props }: IToggleProps) => {
  const checkedState = props.isChecked ? 'ON' : 'OFF'
  const defaulLabel = props.showLabel ? checkedState : null

  return (
    <label className={theme.toggleLabel}>
      <input
        checked={props.isChecked}
        onChange={() => {
          if (!props.disabled) {
            props.onToggle(!props.isChecked)
          }
        }}
        className={theme.toggle}
        type="checkbox"
      />
      <div className={classnames(theme.toggleContainer, { [theme.disabled]: props.disabled === true })}>
        {props.showLabel ? <div className={theme.toggleText}>{props.label || defaulLabel}</div> : null}
        <div className={theme.toggleDot} />
      </div>
    </label>
  )
}
