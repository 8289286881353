import classNames from 'classnames'
import * as React from 'react'

import { renderUndefinable } from '../../utils/rendering'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

import styles from './Message.module.scss'

type MessageProps = Readonly<{
  children: React.ReactNode
  icon?: 'warning'
  iconPosition?: 'top' | 'center'
}>

export const Message = (props: MessageProps) => {
  return (
    <div className={styles.withHeight}>
      <div className={classNames(styles.message, { [styles.iconTop]: props.iconPosition === 'top' })}>
        {renderUndefinable(props.icon, icon => (
          <MaterialIcon className={styles.icon} type={icon} />
        ))}
        <div>{props.children}</div>
      </div>
    </div>
  )
}
