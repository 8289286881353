export const HOME_PAGE = 'HOME_PAGE'
export const HELP_PAGE = 'HELP'
export const LEGAL_TERMS_PAGE = 'LEGAL_TERMS'
export const USERS_MANAGEMENT_PAGE = 'USERS_MANAGEMENT'
export const COMPANY_MANAGEMENT_PAGE = 'COMPANY_MANAGEMENT'

export type Page =
  | typeof HOME_PAGE
  | typeof HELP_PAGE
  | typeof LEGAL_TERMS_PAGE
  | typeof USERS_MANAGEMENT_PAGE
  | typeof COMPANY_MANAGEMENT_PAGE

export const PAGES: Record<Page, string> = {
  [HOME_PAGE]: '/',
  [HELP_PAGE]: '/help',
  [LEGAL_TERMS_PAGE]: '/legal-terms',
  [USERS_MANAGEMENT_PAGE]: '/users-management',
  [COMPANY_MANAGEMENT_PAGE]: '/company-management',
}
