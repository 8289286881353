export interface IBugReport {
  email: string
  subject: string
  content: string
  attachment?: string | ArrayBuffer
}

export function createBugReport(
  subject: string,
  content: string,
  attachedFile: string | ArrayBuffer | false,
  email?: string
): FormData {
  const formData = new FormData()
  formData.append('email', email ?? 'Unknown')
  formData.append('subject', subject)
  formData.append('content', content)
  if (typeof attachedFile === 'string') {
    formData.append('attachment', attachedFile)
  }
  return formData
}
