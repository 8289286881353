import { useState } from 'react'

import { BugReporter } from '../../components/BugReporter/BugReporter.container'
import {
  DrillDownLevel,
  DRILL_DOWN_LEFT,
  DrillDownLevelTheme,
  DRILL_DOWN_RIGHT,
} from '../../components/SideMenu/DrillDownLevel'
import { SideMenu } from '../../components/SideMenu/SideMenu'
import { SideMenuContent } from '../../components/SideMenu/SideMenuContent'
import { SideMenuFooter } from '../../components/SideMenu/SideMenuFooter'
import { SideMenuHeader } from '../../components/SideMenu/SideMenuHeader'
import {
  SideMenuItemInternal as SideMenuItemInternalInner,
  SideMenuItemButton,
  SideMenuItemExternal as SideMenuItemExternalInner,
  SideMenuItemInternalProps,
  SideMenuItemExternalProps,
} from '../../components/SideMenu/SideMenuItem'
import { SideMenuSpacer } from '../../components/SideMenu/SideMenuSpacer'
import { PILOT_TRACKER_URL, SHIP_TRACKER_URL } from '../../config'
import { PAGES } from '../../constants/pages'
import { IUser } from '../../shared/interfaces/settings/Settings'
import { none } from '../../utils/strictNull'

import styles from './Header.module.scss'

interface IHeaderProps {
  user: IUser
  onClose: () => void
  onLogout: () => void
  shouldShowLinkToUsersManagement: boolean
  shouldShowLinkToCompanyManagement: boolean
  shouldShowLinkToPilotTracker: boolean
  shouldShowLinkToShipTracker: boolean
}

enum SubSideMenu {
  None,
  BugReporter,
}

const sideMenuItemsTheme: DrillDownLevelTheme = {
  drillDownLevel: styles.sideMenuItems,
}

const bugReporterTheme: DrillDownLevelTheme = {
  drillDownLevel: styles.bugReporter,
}

const SideMenuItemInternal = ({ children, page, onClick }: SideMenuItemInternalProps) => (
  <SideMenuItemInternalInner
    page={page}
    onClick={() => {
      onClick?.()
    }}
  >
    {children}
  </SideMenuItemInternalInner>
)

const SideMenuItemExternal = ({ children, url, onClick }: SideMenuItemExternalProps) => (
  <SideMenuItemExternalInner
    url={url}
    onClick={() => {
      onClick?.()
    }}
  >
    {children}
  </SideMenuItemExternalInner>
)

export function Header({
  user,
  onLogout,
  onClose,
  shouldShowLinkToUsersManagement,
  shouldShowLinkToCompanyManagement,
  shouldShowLinkToPilotTracker,
  shouldShowLinkToShipTracker,
}: IHeaderProps) {
  const [sideMenu, setSideMenu] = useState<SubSideMenu>(SubSideMenu.None)

  return (
    <SideMenu isActive onClose={onClose}>
      <DrillDownLevel active={sideMenu === SubSideMenu.None} position={DRILL_DOWN_LEFT} theme={sideMenuItemsTheme}>
        <SideMenuHeader onClose={onClose} />
        <SideMenuContent>
          {/* Internal app views */}
          {shouldShowLinkToUsersManagement && (
            <SideMenuItemInternal page={PAGES.USERS_MANAGEMENT}>Users Management</SideMenuItemInternal>
          )}
          {shouldShowLinkToCompanyManagement && (
            <SideMenuItemInternal page={PAGES.COMPANY_MANAGEMENT}>Company Management</SideMenuItemInternal>
          )}
          {shouldShowLinkToPilotTracker && (
            <SideMenuItemExternal url={PILOT_TRACKER_URL}>PilotTracker</SideMenuItemExternal>
          )}
          {shouldShowLinkToShipTracker && (
            <SideMenuItemExternal url={SHIP_TRACKER_URL}>ShipTracker</SideMenuItemExternal>
          )}

          <SideMenuSpacer />
          {/* Other internal pages / content */}
          <SideMenuItemButton onClick={() => setSideMenu(SubSideMenu.BugReporter)}>Report a bug</SideMenuItemButton>
        </SideMenuContent>
        <SideMenuFooter
          userAvatarUrl={user?.avatarURL ?? none}
          companyName={user?.company.name ?? none}
          onLogout={onLogout}
        />
      </DrillDownLevel>
      <DrillDownLevel
        active={sideMenu === SubSideMenu.BugReporter}
        position={DRILL_DOWN_RIGHT}
        theme={bugReporterTheme}
      >
        <BugReporter onBack={() => setSideMenu(SubSideMenu.None)} />
      </DrillDownLevel>
    </SideMenu>
  )
}
