import { setIn } from 'formik'

import { identity, compose } from './functions'
import { StrictUndefined, StrictNull, None } from './strictNull'

export class Path<P, C> {
  /**
   * Create new Path object with root type P and end type C.
   * P is the root object type to start traversing.
   * C is result type of the traversal.
   */
  public static obj<O>(): Path<O, O> {
    return new Path(identity, [])
  }

  /**
   * Creates an instance of Path
   *
   * @param {(p: P) => C} getter Getter function to fetch a segment of type P
   * @param {(Array<string | number | symbol>)} pathSegments Array of individual path segments for current path of type P
   * @memberof Path
   */
  private constructor(
    public readonly getter: (p: P) => C,
    private readonly pathSegments: Array<string | number | symbol>
  ) {}

  /**
   * Gets the total path string, consisting of all path segments
   *
   * @readonly
   * @memberof Path
   */
  get totalPath() {
    return this.pathSegments.join('.')
  }

  public setter(p: P, c: C) {
    return setIn(p, this.totalPath, c)
  }

  /**
   * Traverses the object using a getter function and a path segment
   *
   * @param getter Getter function to traverse object
   * @param pathSegments New path segment to add to the total path segments
   */
  public traverse<CC>(getter: (c: C) => CC, pathSegments: Array<string | number | symbol>): Path<P, CC> {
    return new Path(compose(this.getter, getter), [...this.pathSegments, ...pathSegments])
  }

  public compose<D>(that: Path<C, D>): Path<P, D> {
    return this.traverse(that.getter, that.pathSegments)
  }

  /**
   * Get key for current nesting level in
   *
   * @param k Key to fetch from current nesting level in P
   */
  public key<K extends keyof C>(k: K): Path<P, C[K]> {
    const getter = (c: C): C[K] => c[k]
    return this.traverse(getter, [k])
  }

  /**
   * Get optionally undefined key for current nesting level in
   *
   * @param this `this` paramater to modify scope to handle undefined cases
   * @param k Optionally undefined key to fetch from current nesting level in P
   */
  public optionalKey<CC, K extends keyof CC>(this: Path<P, CC | undefined>, k: K): Path<P, CC[K] | undefined> {
    const getter = StrictUndefined.lift((c: CC): CC[K] => c[k])
    return this.traverse(getter, [k])
  }

  /**
   * Get Noneable key for current nesting level in
   *
   * @param this `this` paramater to modify scope to handle None cases
   * @param k Optionally Noneable key to fetch from current nesting level in P
   */
  public noneableKey<CC, K extends keyof CC>(this: Path<P, CC | None>, k: K): Path<P, CC[K] | None> {
    const getter = StrictNull.lift((c: CC): CC[K] => c[k])
    return this.traverse(getter, [k])
  }
}
