import { renderIf } from '../../utils/rendering'
import {
  SideSubMenuFormState,
  FORM_STATE_INITIAL,
  FORM_STATE_SUBMIT_ERROR,
} from '../SideSubMenu/SideSubMenuForm/SideSubMenuFormState'
import { SideSubMenuFormButtons } from '../SideSubMenu/SideSubMenuFormButtons/SideSubMenuFormButtons'
import { SideSubMenuHeader } from '../SideSubMenu/SideSubMenuHeader/SideSubMenuHeader'

import { AttachedFileStatus } from './AttachedFileStatus'
import styles from './BugReporter.module.scss'
import { BugReporterForm } from './BugReporterForm'
import { IBugReporterForm } from './IBugReporterForm'

interface IBugReporterProps {
  form: IBugReporterForm
  bugReportState: SideSubMenuFormState
  attachedFileStatus: AttachedFileStatus
  formIsValid: boolean
  onFormChange: (form: Partial<IBugReporterForm>) => void
  onSubmit: () => void
  onBack: () => void
}

export const BugReporter = (props: IBugReporterProps) => (
  <>
    <SideSubMenuHeader onBack={props.onBack} />
    <BugReporterForm
      formState={props.bugReportState}
      form={props.form}
      attachedFileStatus={props.attachedFileStatus}
      onChange={props.onFormChange}
    />
    {renderIf(
      props.bugReportState === FORM_STATE_SUBMIT_ERROR,
      <p className={styles.error}>Sorry, something went wrong.</p>
    )}
    <SideSubMenuFormButtons
      formState={props.bugReportState}
      isValid={props.formIsValid && props.bugReportState === FORM_STATE_INITIAL}
      onSubmit={props.onSubmit}
      onCancel={props.onBack}
    />
  </>
)
