import { reversed, Comparator } from '../../utils/ordering'
import { throwUnreachable } from '../../utils/throwUnreachable'

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export function toggleSortDirection(sortDirection: SortDirection) {
  if (sortDirection === SortDirection.ASC) {
    return SortDirection.DESC
  }
  if (sortDirection === SortDirection.DESC) {
    return SortDirection.ASC
  }
  const exhaustive: never = sortDirection
  throw new Error(exhaustive)
}

export function getComparatorForSortDirection<A>(comparatorAsc: Comparator<A>, sortDirection: SortDirection) {
  switch (sortDirection) {
    case SortDirection.ASC:
      return comparatorAsc
    case SortDirection.DESC:
      return reversed(comparatorAsc)
    default:
      return throwUnreachable(sortDirection)
  }
}

export function getSortDirectionSymbol(sortDirection: SortDirection): string {
  if (sortDirection === SortDirection.ASC) {
    return '▲'
  }
  if (sortDirection === SortDirection.DESC) {
    return '▼'
  }
  const exhaustive: never = sortDirection
  throw new Error(exhaustive)
}
