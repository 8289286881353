import { createRef, Component, RefObject } from 'react'

import classnames from 'classnames'

import { TestIds } from '../../utils/testIds/testIds'

import styles from './Ticker.module.scss'

type TickerProps = Readonly<{
  className?: string
}>

const SCROLL_PIXELS_PER_SECOND = 80

/*
  This Ticker scrolls content that overflows it's parent from right to left to show the user the whole text.
  If the user `mouseOver`s the element it will start scrolling when the content is wider than the parent.
  When the user `mouseLeave`s the element it will scroll back to its original postion.
  The scroll speed is dynamic and based on the amount of pixels to scroll.

  The inner is `absolute` positioned and sticks to the right side of the parent. It uses `min-width` and `max-width`
  which are equal to the parents width. When the user hovers over the element it sets the `max-width` to the width of
  the inner content. (`element.scrollWidth`) Because this makes the inner space wider and causes the left-aligned text to
  scroll to the left.
  This `max-width` needs to be the same as the inner content (`element.scrollWidth`) to determine the correct scroll speed
  and how far it needs to grow for the content to fit.
*/
export class Ticker extends Component<TickerProps> {
  private ref: RefObject<HTMLDivElement> = createRef()

  public render() {
    return (
      <div className={classnames(this.props.className, styles.ticker)} data-test-id={TestIds.Ticker}>
        <div className={styles.inner} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} ref={this.ref}>
          {this.props.children}
        </div>
      </div>
    )
  }

  private onMouseOver = () => {
    const ref = this.ref.current

    if (ref) {
      if (!isOverflown(ref)) {
        return
      }

      const pixelsToScroll = ref.scrollWidth - ref.clientWidth
      const transitionDurationInSeconds = pixelsToScroll / SCROLL_PIXELS_PER_SECOND

      ref.style.maxWidth = `${ref.scrollWidth}px`
      ref.style.transitionDuration = `${transitionDurationInSeconds}s`
    }
  }

  private onMouseLeave = () => {
    // We need to reset the `maxWidth` to let the content slide back to it's original position.
    // We DO NOT reset the `transitionDuration` because we want the content sliding back at the same
    // speed as when hovering on the item.
    if (this.ref.current) {
      this.ref.current.style.maxWidth = ''
    }
  }
}

function isOverflown(element: HTMLElement) {
  return element.scrollWidth > element.clientWidth
}
