import classnames from 'classnames'

import MaterialIcon from '../../MaterialIcon/MaterialIcon'

import styles from './CloseIcon.module.scss'

interface ICloseIcon {
  className?: string
  onClick?: () => void
  size?: 'sm' | 'md' | 'lg'
}

export const CloseIcon = (props: ICloseIcon) => {
  const { size = 'md' } = props
  const styleClasses = classnames(styles.closeIcon, styles[size], props.className)
  return <MaterialIcon className={styleClasses} type="close" onClick={props.onClick} />
}
