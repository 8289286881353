import { EditUserView, NewUserView } from './UsersView'

export const MODAL_TYPE_NEW_USER = 'MODAL_TYPE_NEW_USER'
export const MODAL_TYPE_EDIT_USER = 'MODAL_TYPE_EDIT_USER'

export type ModalNewUser = Readonly<{
  type: typeof MODAL_TYPE_NEW_USER
  user: NewUserView
}>

export type ModalEditUser = Readonly<{
  type: typeof MODAL_TYPE_EDIT_USER
  user: EditUserView
}>
