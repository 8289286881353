export const Strings = {
  append:
    (toAppend: string) =>
    (toAppendTo: string): string => {
      return toAppendTo + toAppend
    },
  fromNumber: (n: number): string => {
    return String(n)
  },
  pluralise: (count: number, singular: string, plural: string): string => {
    // In English, only 1 is singular; the other numbers are plural.
    // https://english.stackexchange.com/a/38294
    return count === 1 ? singular : plural
  },
  localeCompare: (strA: string, strB: string) => strA.toLowerCase().localeCompare(strB.toLowerCase()),
}
