import { Auth0ClientOptions } from '@auth0/auth0-spa-js'

import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../../config'

export const auth0Options: Auth0ClientOptions = {
  domain: AUTH0_DOMAIN,
  client_id: AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: 'pronto-api',
  useRefreshTokens: true,
}
