import * as React from 'react'

import classnames from 'classnames'

import styles from './SideMenu.module.scss'

interface ISideMenuProps {
  isActive: boolean

  onClose: () => void

  children: React.ReactNode
}

export const SideMenu = (props: ISideMenuProps) => (
  <>
    <div className={classnames(styles.sideMenuOverlay, { [styles.active]: props.isActive })} onClick={props.onClose} />
    <div className={classnames(styles.sideMenu, { [styles.active]: props.isActive })}>{props.children}</div>
  </>
)
