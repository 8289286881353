import { UserId } from '../../../ApplicationState/UsersManagement/User'
import { throwUnreachable } from '../../../utils/throwUnreachable'
import { GroupView } from '../../../View/UsersManagement/GroupView'
import { LocaleView } from '../../../View/UsersManagement/LocaleView'
import {
  ModalNewUser,
  ModalEditUser,
  MODAL_TYPE_EDIT_USER,
  MODAL_TYPE_NEW_USER,
} from '../../../View/UsersManagement/Modal'
import { PortView } from '../../../View/UsersManagement/PortView'
import { RoleView } from '../../../View/UsersManagement/RoleView'
import { NewUserView, EditUserView } from '../../../View/UsersManagement/UsersView'

import { UserManagementModalEditUser } from './EditUser/UserManagementModalEditUser'
import { UserManagementModalNewUser } from './NewUser/UserManagementModalNewUser'

type UserManagementModalProps = Readonly<{
  modal: ModalNewUser | ModalEditUser
  roles: RoleView[]
  groups: GroupView[]
  ports: PortView[]
  locales: LocaleView[]
  onSave: (modal: ModalNewUser | ModalEditUser) => void
  onDelete: (userId: UserId) => void
  onClose: () => void
}>

export function UserManagementModal(props: UserManagementModalProps) {
  switch (props.modal.type) {
    case MODAL_TYPE_NEW_USER: {
      const onSaveNewUser = (user: NewUserView) => {
        props.onSave({
          type: MODAL_TYPE_NEW_USER,
          user,
        })
      }
      return (
        <UserManagementModalNewUser
          user={props.modal.user}
          roles={props.roles}
          groups={props.groups}
          ports={props.ports}
          locales={props.locales}
          onSave={onSaveNewUser}
          onClose={props.onClose}
        />
      )
    }
    case MODAL_TYPE_EDIT_USER: {
      const onSaveEditUser = (user: EditUserView) => {
        props.onSave({
          type: MODAL_TYPE_EDIT_USER,
          user,
        })
      }
      return (
        <UserManagementModalEditUser
          user={props.modal.user}
          roles={props.roles}
          groups={props.groups}
          ports={props.ports}
          locales={props.locales}
          onSave={onSaveEditUser}
          onDelete={props.onDelete}
          onClose={props.onClose}
        />
      )
    }
    default:
      throwUnreachable(props.modal)
  }
}
