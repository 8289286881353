import * as React from 'react'

import { Company } from '../../../ApplicationState/Company/Company'
import { IDateRangeOption, IDateRangeOptions } from '../../../store/filters/filters.interfaces'
import { identity } from '../../../utils/functions'
import { compareNumbersAsc } from '../../../utils/ordering'
import { StrictUndefined } from '../../../utils/strictNull'
import { createFilterableColumn } from '../../../View/Tables/FilterableColumn'
import { createSortableColumn } from '../../../View/Tables/SortableColumn'
import { getDefaultOptionFromDateRangeOptions } from '../../Dropdown/getDefaultOptionFromDateRangeOptions'
import { createTable, TableConfigColumns, TableTheme } from '../../Table/Table'

import styles from './CompanyManagementTable.module.scss'

export type CompanyManagementTableProps = Readonly<{
  companies: Company[]
  dateRangeOptions: IDateRangeOptions
  onEdit: (company: Company) => void
}>

enum FilterableColumnType {
  CompanyName = 'CompanyName',
}

enum SortableColumnType {
  DateRangeStart = 'DateRangeStart',
  DateRangeEnd = 'DateRangeEnd',
}

const tableTheme: TableTheme = {
  header: styles.header,
  body: styles.body,
  row: styles.row,
  cell: styles.cell,
}

const cellStyles: Record<FilterableColumnType | SortableColumnType, string> = {
  [FilterableColumnType.CompanyName]: styles.companyName,
  [SortableColumnType.DateRangeStart]: styles.dateRangeStart,
  [SortableColumnType.DateRangeEnd]: styles.dateRangeEnd,
}

type AdditionalProps = Readonly<{
  dateRangeOptions: IDateRangeOptions
  onEdit: (company: Company) => void
}>

function formatDateRangeHours(numberOfHours: number, dateRangeOptions: IDateRangeOption[]) {
  const foundDateRangeOption = dateRangeOptions.find(dateRangeOption => dateRangeOption.value === numberOfHours)
  return StrictUndefined.fold(
    foundDateRangeOption,
    ({ label }) => label,
    formatBestMatchingDateRange(numberOfHours, dateRangeOptions)
  )
}

function formatBestMatchingDateRange(numberOfHours: number, dateRangeOptions: IDateRangeOption[]) {
  const bestMatchingDateRangeOption = getDefaultOptionFromDateRangeOptions(numberOfHours, dateRangeOptions)
  return `${bestMatchingDateRangeOption.label} (${numberOfHours}H)`
}

const columns: TableConfigColumns<
  never,
  SortableColumnType,
  FilterableColumnType,
  Company,
  React.ReactNode,
  AdditionalProps
> = {
  [FilterableColumnType.CompanyName]: createFilterableColumn(
    FilterableColumnType.CompanyName,
    'Company Name',
    c => c.name,
    identity,
    identity
  ),
  [SortableColumnType.DateRangeStart]: createSortableColumn(
    SortableColumnType.DateRangeStart,
    'Range start',
    c => c.allowedDateRange.start,
    (numberOfHours, company, additionalProps) =>
      formatDateRangeHours(numberOfHours, additionalProps.dateRangeOptions.startOptions),
    compareNumbersAsc
  ),
  [SortableColumnType.DateRangeEnd]: createSortableColumn(
    SortableColumnType.DateRangeEnd,
    'Range end',
    c => c.allowedDateRange.end,
    (numberOfHours, company, additionalProps) =>
      formatDateRangeHours(numberOfHours, additionalProps.dateRangeOptions.offsetOptions),
    compareNumbersAsc
  ),
}

const Table = createTable<never, SortableColumnType, FilterableColumnType, Company, React.ReactNode, AdditionalProps>({
  columns,
  allColumnTypes: [
    FilterableColumnType.CompanyName,
    SortableColumnType.DateRangeStart,
    SortableColumnType.DateRangeEnd,
  ],
  sortableColumnTypes: [SortableColumnType.DateRangeStart, SortableColumnType.DateRangeEnd],
  filterableColumnTypes: [FilterableColumnType.CompanyName],
  getKey: company => company.id.toString(),
  styles: cellStyles,
  rowWidth: 500,
  onClick: (company, index, additionalProps) => {
    additionalProps.onEdit(company)
  },
})

export const CompanyManagementTable = (props: CompanyManagementTableProps) => (
  <Table theme={tableTheme} values={props.companies} dateRangeOptions={props.dateRangeOptions} onEdit={props.onEdit} />
)
