export const NO_ATTACHED_FILE_SELECTED = 'NO_ATTACHED_FILE_SELECTED'
export const ATTACHED_FILE_OK = 'ATTACHED_FILE_OK'
export const ATTACHED_FILE_ERROR = 'ATTACHED_FILE_ERROR'

interface INoAttachedFileSelected {
  type: typeof NO_ATTACHED_FILE_SELECTED
}

interface IAttachedFileOk {
  type: typeof ATTACHED_FILE_OK
  fileName: string
}

interface IAttachedFileError {
  type: typeof ATTACHED_FILE_ERROR
  message: string
}

export type AttachedFileStatus = INoAttachedFileSelected | IAttachedFileOk | IAttachedFileError
