import classnames from 'classnames'

import DropdownStyle from './Dropdown.module.scss'

export interface IDropdownOption<Value> {
  label: string
  value: Value
}

export interface IDropdownListProps<Value> {
  options: Array<IDropdownOption<Value>>
  onChange: (option: IDropdownOption<Value>) => void
  defaultValue?: Value
  selectedOption?: IDropdownOption<Value>
  isOpen: boolean
}

export interface IDropdownState<Value> {
  isOpen: boolean
  selectedOption?: IDropdownOption<Value>
}

export interface IDropdownBackdropProps {
  onClick: () => void
  className?: string
  visible: boolean
}

interface IDropdownProps<Value> {
  dropdownClassNames?: string
  backdrop: IDropdownBackdropProps
  selectOption: JSX.Element
  dropdownList: IDropdownListProps<Value>
}

function DropdownList<Value = number | string>(props: IDropdownListProps<Value>) {
  const isSelectedOption = (option: IDropdownOption<Value>) => {
    return props.selectedOption !== undefined && props.selectedOption.value === option.value
  }

  const isDefaultOption = (option: IDropdownOption<Value>) => {
    return props.defaultValue === option.value
  }

  const getOption = (option: IDropdownOption<Value>) => {
    return (
      <li
        className={classnames(
          DropdownStyle.option,
          isSelectedOption(option) || isDefaultOption(option) ? DropdownStyle.isSelected : null
        )}
        key={option.label}
        onClick={() => props.onChange(option)}
      >
        {option.label}
      </li>
    )
  }

  const areThereOptions = () => {
    return props.options && props.options.length > 0
  }

  return (
    (areThereOptions() && (
      <ul className={classnames(DropdownStyle.dropdownOptionsList, props.isOpen ? DropdownStyle.openDropdown : null)}>
        {props.options.map(option => getOption(option))}
      </ul>
    )) ||
    null
  )
}

const DropdownBackdrop = (props: IDropdownBackdropProps) =>
  props.visible ? <div className={classnames(DropdownStyle.backdrop, props.className)} onClick={props.onClick} /> : null

export function Dropdown<Value = string | number>(props: IDropdownProps<Value>) {
  return (
    <div
      className={classnames(
        DropdownStyle.dropdown,
        props.dropdownClassNames,
        props.backdrop.visible ? DropdownStyle.isActive : null
      )}
    >
      <DropdownBackdrop {...props.backdrop} />
      <div className={classnames(DropdownStyle.dropdownListWrapper)}>
        {props.selectOption}
        <DropdownList {...props.dropdownList} />
      </div>
    </div>
  )
}
