import { IDateRangeOption } from '../../store/filters/filters.interfaces'

// This function assumes that the values in dateRangeOptions are sorted:
// - from low to high in case of positive values: +12h +24h +48h ...
// - from high to low in case of negative values: -12h -24h -48h ...
//
// It returns either the exact matching dateRangeOption or the first closest option towards the 0.
//
// Example: getting the best dateRangeOption for 15hr from an array of [+12hr, +24hr] returns 12hr
export const getDefaultOptionFromDateRangeOptions = (
  defaultValue: number,
  dateRangeOptions: IDateRangeOption[]
): IDateRangeOption => {
  return dateRangeOptions.reduce(
    (previousValue, currentValue) =>
      Math.abs(defaultValue) < Math.abs(currentValue.value) ? previousValue : currentValue,
    dateRangeOptions[0]
  )
}
