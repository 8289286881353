import { Message } from '../../Message/Message'

import styles from './CompanyManagementWarning.module.scss'

export const CompanyManagementWarning = () => {
  return (
    <div className={styles.warning}>
      <Message icon="warning">List with companies not available</Message>
    </div>
  )
}
