import * as React from 'react'

import classnames from 'classnames'

import { CloseIcon } from '../Icons/CloseIcon/CloseIcon'

import { ModalLayout, modalLayoutOneColumn } from './Layout/ModalLayout'
import styles from './Modal.module.scss'

type ModalProps = Readonly<{
  className?: string
  onClose: () => void
  children: React.ReactNode
  layout?: ModalLayout
  modalRef?: React.Ref<HTMLDivElement>
}>

const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()

// This modal has some tricks & hacks to make it work.
// The modal will work and it will be able to close it regardless of the screen width,
// there are many onClick to ensure this happening, all onClose are needed.
// .modalContainer: Scroll is done in this element.
// .withPadding: onClose is needed to close the modal if clicked on the padding.
// .withCloseButton, this element can expand itself (flex-grow) if the passed layout says so.
// Content wrapper styles are defined in the layout prop.
export const Modal = (props: ModalProps) => {
  const { layout = modalLayoutOneColumn } = props
  return (
    <div className={classnames(styles.modalContainer, props.className)} onClick={props.onClose}>
      <div
        className={styles.withPadding}
        onClick={props.onClose}
        style={{
          maxWidth: layout.maxWidth,
        }}
      >
        <div
          className={classnames(styles.withCloseButton, {
            [styles.flexGrow]: layout.expand,
          })}
          onClick={stopPropagation}
        >
          <div className={styles.closeRow} onClick={props.onClose}>
            <CloseIcon onClick={props.onClose} />
          </div>
          <div className={layout.stylesContent} ref={props.modalRef}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
