import { useState } from 'react'
import * as React from 'react'

import { uniqueId } from 'lodash'

import { identity } from '../../../../utils/functions'
import { renderNoneable } from '../../../../utils/rendering'
import { None } from '../../../../utils/strictNull'
import { TestIds } from '../../../../utils/testIds/testIds'

import styles from './ModalFormItem.module.scss'

type ModalFormItemProps = Readonly<{
  label: string
  name: string
  feedback: JSX.Element | string | None
  children: (id: string) => React.ReactNode
  testId?: TestIds
}>

export const ModalFormItem = (props: ModalFormItemProps) => {
  const [id] = useState<string>(() => uniqueId())
  return (
    <div className={styles.formItem} data-test-id={props.testId}>
      <label className={styles.label} htmlFor={id}>
        <span className={styles.labelText}>{props.label}</span>
      </label>
      {/* The easiest would probably be to put the `children` inside */}
      {/* the `<label>`. This has the downside that every click event */}
      {/* that happens inside `children` bubbles up to the `<label>`, which */}
      {/* which simulates a click on the input element. */}
      {props.children(id)}
      <span className={styles.feedback}>{renderNoneable(props.feedback, identity)}</span>
    </div>
  )
}
