export type ItemPosition = Readonly<{
  index: number
  offsetTop: number
  offsetBottom: number
  height: number
}>

export type ItemPositions = ItemPosition[]

export function getItemPositions(numberOfItems: number, itemHeight: (index: number) => number): ItemPositions {
  const itemPositions: ItemPositions = new Array(numberOfItems)
  let index = 0
  let offsetTop = 0
  while (index < numberOfItems) {
    const height = itemHeight(index)
    const offsetBottom = offsetTop + height
    itemPositions[index] = {
      index,
      offsetTop,
      offsetBottom,
      height,
    }
    index++
    offsetTop += height
  }
  return itemPositions
}
