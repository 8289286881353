import * as React from 'react'

import { renderIf, renderNoneable } from '../../../../utils/rendering'
import { None } from '../../../../utils/strictNull'
import { TestIds } from '../../../../utils/testIds/testIds'
import { Button } from '../../../Button/Button'
import { ButtonRole } from '../../../Button/ButtonRole'
import { ModalFooter } from '../../../Modal/Footer/ModalFooter'
import { ModalHeader } from '../../../Modal/Header/ModalHeader'
import { ModalContent } from '../../../Modal/ModalContent'
import { ModalSubtitle } from '../../../Modal/Subtitle/ModalSubtitle'
import { ModalTitle } from '../../../Modal/Title/ModalTitle'

import styles from './Confirm.module.scss'

type ConfirmProps = Readonly<{
  title: React.ReactNode
  subtitle?: React.ReactNode
  text: React.ReactNode
  confirmationButtonLabel: React.ReactNode
  onConfirm: () => void
  onCancel: (() => void) | None
  testId?: TestIds
}>

export const Confirm = (props: ConfirmProps) => {
  return (
    <>
      <ModalHeader>
        <ModalTitle>{props.title}</ModalTitle>
        {renderIf(props.subtitle !== undefined, <ModalSubtitle>{props.subtitle}</ModalSubtitle>)}
      </ModalHeader>
      <ModalContent className={styles.modalContent}>
        <div className={styles.content}>
          <div className={styles.text}>{props.text}</div>
        </div>
      </ModalContent>
      <ModalFooter>
        {renderNoneable(props.onCancel, onCancel => (
          <Button onClick={onCancel} role={ButtonRole.Secondary}>
            Cancel
          </Button>
        ))}
        <Button onClick={props.onConfirm} testId={props.testId}>
          {props.confirmationButtonLabel}
        </Button>
      </ModalFooter>
    </>
  )
}
