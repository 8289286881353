import { ItemPositions, ItemPosition } from './ItemPosition'

export function getVisibleItems(
  scrollTop: number,
  clientHeight: number,
  overscanCount: number,
  itemPositions: ItemPositions
): ItemPosition[] {
  const scrollBottom = scrollTop + clientHeight
  const firstVisibleItemIndex = itemPositions.findIndex(itemPosition => scrollTop <= itemPosition.offsetBottom)
  if (firstVisibleItemIndex === -1) {
    return []
  }
  const indexStart = Math.max(firstVisibleItemIndex - overscanCount, 0)
  const numberOfItemPositions = itemPositions.length
  let currentIndex = firstVisibleItemIndex
  while (currentIndex < numberOfItemPositions && itemPositions[currentIndex].offsetTop < scrollBottom) {
    currentIndex++
  }
  const indexEnd = Math.min(currentIndex + overscanCount, numberOfItemPositions)
  return itemPositions.slice(indexStart, indexEnd)
}
