import * as React from 'react'

import classnames from 'classnames'

interface IMaterialIconProps {
  type: string
  className?: string
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void
  title?: string
  ariaLabel?: string
}

const MaterialIcon = (props: IMaterialIconProps) => (
  <span
    onClick={props.onClick}
    className={classnames('material-icons', props.className)}
    title={props.title}
    aria-label={props.ariaLabel}
  >
    {props.type}
  </span>
)

export default MaterialIcon
