import { CornerCloseButton } from '../Buttons/CornerCloseButton/CornerCloseButton'
import HeaderLogo from '../Header/HeaderLogo/HeaderLogo'

import styles from './SideMenuHeader.module.scss'

interface ISideMenuHeaderProps {
  onClose: () => void
}

export const SideMenuHeader = (props: ISideMenuHeaderProps) => (
  <div className={styles.sideMenuHeader}>
    <div className={styles.closeIconWrapper}>
      <CornerCloseButton onClose={props.onClose} className={styles.closeSideMenuIcon} />
    </div>
    <div className={styles.headerLogo}>
      <HeaderLogo />
    </div>
  </div>
)
