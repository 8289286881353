import * as React from 'react'

import classnames from 'classnames'
import onClickOutside from 'react-onclickoutside'

import { renderIf, renderEither } from '../../utils/rendering'
import { None } from '../../utils/strictNull'
import { TestIds } from '../../utils/testIds/testIds'
import { ChevronDownIcon } from '../Icon/ChevronDownIcon'
import { ChevronUpIcon } from '../Icon/ChevronUpIcon'
import { Search } from '../MultiSelect/Search'

import styles, { Styles } from './GenericDropdown.module.scss'
import { chevronColor } from './variables'

/**
 * You should use this to set:
 *
 *  - font
 *  - text color
 *  - margin
 *
 * Border styles, background colors, paddings, font-sizes are
 * predefined (but can, of course, be overriden).
 */
export type GenericDropdownTheme = Partial<Styles>

/**
 * The operations that you have available to render an
 * option to the dropdown menu.
 */
type GenericDropdownChildrenProps = Readonly<{
  focusOnHead: () => void
  closeDropdown: () => void
  openDropdown: () => void
  toggleDropdown: () => void
}>

interface ISearchable {
  query: string
  setQuery: (query: string) => void
}

export type GenericDropdownProps = Readonly<{
  id?: string
  theme?: GenericDropdownTheme
  head: React.ReactNode
  body: (props: GenericDropdownChildrenProps) => React.ReactNode
  searchable?: ISearchable | None
}>

type GenericDropdownState = Readonly<{
  dropdownOpen: boolean
}>

export class GenericDropdown extends React.Component<GenericDropdownProps, GenericDropdownState> {
  private headRef: HTMLButtonElement | null

  private childrenProps: GenericDropdownChildrenProps

  constructor(props: GenericDropdownProps) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.headRef = null
    this.childrenProps = {
      openDropdown: this.openDropdown,
      closeDropdown: this.closeDropdown,
      toggleDropdown: this.toggleDropdown,
      focusOnHead: this.focusOnHead,
    }
  }

  public render() {
    const { props, state, childrenProps } = this
    const theme: GenericDropdownTheme = props.theme || {}
    return (
      <div className={classnames(styles.container, theme.container)}>
        <button
          id={props.id}
          type="button"
          ref={this.setHeadRef}
          className={classnames(styles.head, theme.head)}
          onClick={this.toggleDropdown}
        >
          {state.dropdownOpen && props.searchable && (
            <Search query={props.searchable.query} onSearch={props.searchable.setQuery} placeholder="Search" />
          )}
          {renderIf(!state.dropdownOpen || !props.searchable, <span>{props.head}</span>)}
          {renderEither(
            state.dropdownOpen,
            <ChevronUpIcon className={styles.headIcon} color={chevronColor} />,
            <ChevronDownIcon className={styles.headIcon} color={chevronColor} />
          )}
        </button>
        {renderIf(
          state.dropdownOpen,
          <div className={classnames(styles.body, theme.body)} data-test-id={TestIds.GenericDropdown}>
            {props.body(childrenProps)}
          </div>
        )}
      </div>
    )
  }

  public handleClickOutside: React.MouseEventHandler = () => {
    this.closeDropdown()
  }

  private setHeadRef = (headRef: HTMLButtonElement | null) => {
    this.headRef = headRef
  }

  private focusOnHead = () => {
    if (this.headRef !== null) {
      this.headRef.focus()
    }
  }

  private toggleDropdown = () => {
    this.setState(({ dropdownOpen }) => ({ dropdownOpen: !dropdownOpen }))
  }

  private closeDropdown = () => {
    this.setState(() => ({ dropdownOpen: false }))
    if (this.props.searchable) {
      this.props.searchable.setQuery('')
    }
  }

  private openDropdown = () => {
    this.setState(() => ({ dropdownOpen: true }))
  }
}

export const GenericDropdownWithClickOutside = onClickOutside(GenericDropdown)
