import { ChangeEvent, useRef } from 'react'
import * as React from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import classnames from 'classnames'

import MaterialIcon from '../../MaterialIcon/MaterialIcon'

import styles from './ColumnHeaderFilterInput.module.scss'

type ColumnHeaderFilterInputProps = Readonly<{
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  filter: string
  header: string
  placeholder?: string
  trackingCategory?: string
}>

export const ColumnHeaderFilterInput = (props: ColumnHeaderFilterInputProps) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const { track } = useMixpanel()
  const onFocusSelectAll = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select()
    if (props.trackingCategory) {
      track(`${props.trackingCategory} - filter - ${props.header}`)
    }
  }
  return (
    <div className={styles.filterInput}>
      <span className={styles.textAndInput}>
        <input
          className={styles.input}
          type="text"
          value={props.filter}
          onFocus={onFocusSelectAll}
          onChange={props.onChange}
          ref={inputEl}
          placeholder={props.placeholder}
        />
        {/* Needs to be in a <span /> so we can change its opacity: */}
        <span>{props.filter || props.header}</span>
      </span>
      <MaterialIcon
        className={classnames(styles.searchIcon, { [styles.active]: !!props.filter })}
        type="search"
        onClick={() => {
          if (inputEl.current !== null) {
            inputEl.current.focus()
          }
        }}
      />
    </div>
  )
}
