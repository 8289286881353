import { GroupId, createGroupId } from '../../ApplicationState/UsersManagement/Group'

export type GroupView = Readonly<{
  id: GroupId
  name: string
}>

export function toGroupView(groupId: string): GroupView {
  return {
    id: createGroupId(groupId),
    name: groupId,
  }
}
