import * as React from 'react'

import classnames from 'classnames'
import { Key as KeyCode } from 'ts-keycode-enum'

import { Key } from '../../constants'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

import styles from './Search.module.scss'

interface ISearchProps {
  query: string
  placeholder: string
  theme?: ISearchTheme
  onSearch: (query: string) => void
  onSubmit?: () => void
  onClose?: () => void
}

interface ISearchTheme {
  icon?: string
  search?: string
  input?: string
}

const defaultTheme: ISearchTheme = {
  icon: undefined,
  search: undefined,
  input: undefined,
}

export class Search extends React.PureComponent<ISearchProps> {
  public render() {
    const theme = {
      ...defaultTheme,
      ...this.props.theme,
    }

    return (
      <div className={classnames(styles.search, theme.search)}>
        <input
          type="text"
          className={classnames(styles.input, theme.input)}
          placeholder={this.props.placeholder}
          value={this.props.query}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          autoFocus
        />
        {this.props?.theme?.icon && <MaterialIcon type="code" className={classnames(styles.icon, theme.icon)} />}
      </div>
    )
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => this.props.onSearch(event.target.value)

  private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== undefined) {
      switch (e.key) {
        case Key.Enter:
          return this.props.onSubmit && this.props.onSubmit()
        case Key.Escape:
          return this.props.onClose && this.props.onClose()
        default:
          return undefined
      }
    }
    switch (e.keyCode) {
      // keyCode is deprecated but still needed according to MDN
      case KeyCode.Enter:
        return this.props.onSubmit && this.props.onSubmit()
      case KeyCode.Escape:
        return this.props.onClose && this.props.onClose()
      default:
        return undefined
    }
  }
}
