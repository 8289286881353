import { ErrorMessage } from 'formik'

import { Path } from '../../../../utils/Path'
import { TestIds } from '../../../../utils/testIds/testIds'
import { FieldProps } from '../../../FormField/FieldProps'
import { FormField } from '../../../FormField/FormField'
import { ModalFormInput } from '../Input/ModalFormInput'

type ModalInputFieldProps<P> = Readonly<{
  label: string
  name: Path<P, string>
  testId?: TestIds
}>

export function ModalInputField<P>(props: ModalInputFieldProps<P>) {
  return (
    <FormField name={props.name}>
      {({ field }: FieldProps<P, string>) => (
        <ModalFormInput
          label={props.label}
          testId={props.testId}
          feedback={<ErrorMessage name={field.name} />}
          {...field}
        />
      )}
    </FormField>
  )
}
