import { Company } from '../../../ApplicationState/Company/Company'
import { IDateRangeOptions } from '../../../store/filters/filters.interfaces'
import { renderNoneable } from '../../../utils/rendering'
import { None } from '../../../utils/strictNull'
import { ContentPage } from '../../ManagementPage/ContentPage'
import { CompanyManagementModal } from '../Modal/CompanyManagementModal'
import { CompanyManagementTable } from '../Table/CompanyManagementTable'

export type CompanyManagementOverviewProps = Readonly<{
  companies: Company[]
  dateRangeOptions: IDateRangeOptions
  editingCompany: Company | None
  errorMessage: string | None
  onEdit: (company: Company) => void
  onCloseModal: () => void
  onSaveModal: (company: Company) => void
}>

export const CompanyManagementOverview = (props: CompanyManagementOverviewProps) => {
  return (
    <ContentPage>
      <h1>Company Management</h1>
      <CompanyManagementTable
        companies={props.companies}
        dateRangeOptions={props.dateRangeOptions}
        onEdit={props.onEdit}
      />
      {renderNoneable(props.editingCompany, company => (
        <CompanyManagementModal
          company={company}
          dateRangeOptions={props.dateRangeOptions}
          errorMessage={props.errorMessage}
          onClose={props.onCloseModal}
          onSave={props.onSaveModal}
        />
      ))}
    </ContentPage>
  )
}
