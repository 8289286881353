import * as React from 'react'

import styles from './SideMenuContent.module.scss'

interface ISideMenuContentProps {
  children: React.ReactNode
}

export const SideMenuContent = (props: ISideMenuContentProps) => (
  <div className={styles.sideMenuContent}>{props.children}</div>
)
