import { Component } from 'react'

import { IDateRangeOption } from '../../store/filters/filters.interfaces'

import { IDropdownOption, IDropdownState, IDropdownBackdropProps, IDropdownListProps, Dropdown } from './Dropdown'
import DropdownStyle from './Dropdown.module.scss'
import { getDefaultOptionFromDateRangeOptions } from './getDefaultOptionFromDateRangeOptions'

interface IDatesDropdown {
  options: IDateRangeOption[]
  onChange: (value: number) => void
  initialValue: number
}

export class DatesDropdown extends Component<IDatesDropdown, IDropdownState<number>> {
  constructor(props: IDatesDropdown) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  public render() {
    const { state, props } = this
    const defaultOption = getDefaultOptionFromDateRangeOptions(this.props.initialValue, this.props.options)
    const selectOption = (
      <div onClick={this.toggleOppenDropdown} className={DropdownStyle.selectedOption}>
        {state.selectedOption?.label ?? defaultOption.label}
      </div>
    )

    const backdrop: IDropdownBackdropProps = {
      onClick: this.closeDropdown,
      visible: state.isOpen,
    }

    const dropdownList: IDropdownListProps<number> = {
      options: props.options,
      defaultValue: props.initialValue,
      onChange: option => this.onDropdownChange(option),
      selectedOption: state.selectedOption,
      isOpen: state.isOpen,
    }

    return (
      <Dropdown
        selectOption={selectOption}
        backdrop={backdrop}
        dropdownList={dropdownList}
        dropdownClassNames={DropdownStyle.datesDropdown}
      />
    )
  }

  private toggleOppenDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  private closeDropdown = () => {
    this.setState(() => ({
      isOpen: false,
    }))
  }

  private onDropdownChange(option: IDropdownOption<number>) {
    this.setState(
      () => ({
        selectedOption: option,
      }),
      () => {
        this.toggleOppenDropdown()
        this.props.onChange(Number(option.value))
      }
    )
  }
}
