import { GroupView } from '../../../../View/UsersManagement/GroupView'
import { LocaleView } from '../../../../View/UsersManagement/LocaleView'
import { PortView } from '../../../../View/UsersManagement/PortView'
import { RoleView } from '../../../../View/UsersManagement/RoleView'
import { NewUserView } from '../../../../View/UsersManagement/UsersView'
import { Modal } from '../../../Modal/Modal'
import { UserManagementModalForm } from '../Form/UserManagementModalForm'

type UserManagementModalNewUserProps = Readonly<{
  onSave: (user: NewUserView) => void
  user: NewUserView
  roles: RoleView[]
  groups: GroupView[]
  ports: PortView[]
  locales: LocaleView[]
  onClose: () => void
}>

export const UserManagementModalNewUser = (props: UserManagementModalNewUserProps) => {
  return (
    <Modal onClose={props.onClose}>
      <UserManagementModalForm
        title="New user"
        user={props.user}
        roles={props.roles}
        groups={props.groups}
        ports={props.ports}
        locales={props.locales}
        onSave={props.onSave}
      />
    </Modal>
  )
}
