import { GroupId } from '../../ApplicationState/UsersManagement/Group'
import { createLocaleId, LocaleId } from '../../ApplicationState/UsersManagement/Locale'
import { RoleId } from '../../ApplicationState/UsersManagement/Role'
import { User, UserId } from '../../ApplicationState/UsersManagement/User'
import { PRONTO_BACKEND_URL } from '../../config'
import { Unlocode } from '../../Domain/NauticalLocations/IPort'
import { SystemOfMeasurement } from '../../Domain/Users/ClientPreferences/SystemOfMeasurement'
import { empty } from '../../utils/arr'
import { identity } from '../../utils/functions'
import { StrictUndefined } from '../../utils/strictNull'

// Auth0 makes all fields (except auth0Id) optional
export type UserJson = Readonly<{
  agents?: string[]
  auth0Id: UserId // this is the userId required to UPDATE or DELETE; user/:id
  berths?: string[]
  blocked?: boolean
  carriers?: string[]
  clientPreferences?: Record<string, unknown>
  email?: string
  firstName?: string
  groups?: GroupId[]
  lastName?: string
  permissions?: string[]
  ports?: Unlocode[]
  roles?: RoleId[]
  defaultHost?: string
  locale?: LocaleId
  userID?: string // Internal id, shouldn't be used.
  measurementUnit: SystemOfMeasurement
}>

const DEFAULT_STRING = 'Unknown'
export const toUserFromUserJson = (json: UserJson): User => {
  return {
    id: json.auth0Id,
    firstName: StrictUndefined.fold(json.firstName, identity, DEFAULT_STRING),
    lastName: StrictUndefined.fold(json.lastName, identity, DEFAULT_STRING),
    email: StrictUndefined.fold(json.email, identity, DEFAULT_STRING),
    blocked: StrictUndefined.fold(json.blocked, identity, false),
    roles: StrictUndefined.orElse(json.roles, empty),
    groups: StrictUndefined.orElse(json.groups, empty),
    ports: StrictUndefined.orElse(json.ports, empty),
    defaultHost: StrictUndefined.orElse(json.defaultHost, PRONTO_BACKEND_URL),
    locale: StrictUndefined.orElse(json.locale, createLocaleId('en_GB')),
    measurementUnit: StrictUndefined.orElse(json.measurementUnit, SystemOfMeasurement.Metric),
  }
}

export type UserJsonWithoutId = Omit<UserJson, 'auth0Id'>

export const toUserJsonFromUser = <Id>(user: User<Id>): UserJsonWithoutId => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    blocked: user.blocked,
    roles: user.roles,
    groups: user.groups,
    ports: user.ports,
    defaultHost: user.defaultHost,
    locale: user.locale,
    measurementUnit: user.measurementUnit,
  }
}
