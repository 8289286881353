import { getAuthToken } from '../../utils/auth/authClientObservable'
import { BACKEND_URL } from '../constants'
import { IProntoConfig } from '../interfaces/settings/Settings'
import { createHeaders } from '../utils/createHeaders'
import { handleJSONResponse } from '../utils/rest'

export async function fetchConfig(): Promise<IProntoConfig> {
  const authToken = await getAuthToken()

  const url = `${BACKEND_URL}/api/config`
  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }

  return fetch(url, options).then(response => handleJSONResponse(options, response))
}
