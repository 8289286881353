import { BACKEND_URL } from '../../shared/constants'
import { getAuthToken } from '../../utils/auth/authClientObservable'
import { none, None } from '../../utils/strictNull'

export async function postBugReport(bugReport: FormData): Promise<None> {
  const authToken = await getAuthToken()
  const options: RequestInit = {
    method: 'POST',
    body: bugReport,
    headers: { 'X-Requested-With': 'Pronto', Authorization: `Bearer ${authToken}` },
  }
  return fetch(`${BACKEND_URL}/api/supportTicket`, options).then(response => {
    if (response.status === 200) {
      return Promise.resolve(none)
    }
    throw new Error()
  })
}
