import { PureComponent } from 'react'

import { IDateRange } from '../../shared/interfaces/filters/IFilters'
import { IDateRangeOptions } from '../../store/filters/filters.interfaces'
import { DatesDropdown } from '../Dropdown/DatesDropdown'
import styles from '../Filters/Filters.module.scss'

export interface IDateRangeFilterOptionProps {
  dateRange: IDateRange
  dateRangeOptions: IDateRangeOptions
  label: string
  onChange: (dateRange: IDateRange) => void
}

export class DateRangeOption extends PureComponent<IDateRangeFilterOptionProps> {
  public render() {
    const { startOptions, offsetOptions } = this.props.dateRangeOptions
    return (
      <>
        <span className={styles.optionTitle}>{this.props.label}</span>
        <div className={styles.timeWindowFilters}>
          <div className={styles.timeWindowFilter}>
            <DatesDropdown
              key={this.props.dateRange.start}
              options={startOptions}
              onChange={(start: number) => this.onChange({ start })}
              initialValue={this.props.dateRange.start}
            />
          </div>
          <div className={styles.timeWindowFilter}>
            <DatesDropdown
              key={this.props.dateRange.end}
              options={offsetOptions}
              onChange={(end: number) => this.onChange({ end })}
              initialValue={this.props.dateRange.end}
            />
          </div>
        </div>
      </>
    )
  }

  private onChange(changes: Partial<IDateRange>) {
    const newDateRange = {
      ...this.props.dateRange,
      ...changes,
    }
    this.props.onChange(newDateRange)
  }
}
