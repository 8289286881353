import { useMemo } from 'react'

import { Formik, FormikProps, Form } from 'formik'
import { object, string, mixed, array, boolean } from 'yup'

import { GroupId } from '../../../../ApplicationState/UsersManagement/Group'
import { LocaleId } from '../../../../ApplicationState/UsersManagement/Locale'
import { RoleId } from '../../../../ApplicationState/UsersManagement/Role'
import { PRODUCT_NAME } from '../../../../config'
import { Unlocode } from '../../../../Domain/NauticalLocations/IPort'
import { SystemOfMeasurement } from '../../../../Domain/Users/ClientPreferences/SystemOfMeasurement'
import { Path } from '../../../../utils/Path'
import { TestIds } from '../../../../utils/testIds/testIds'
import { GroupView } from '../../../../View/UsersManagement/GroupView'
import { LocaleView } from '../../../../View/UsersManagement/LocaleView'
import { PortView } from '../../../../View/UsersManagement/PortView'
import { RoleView } from '../../../../View/UsersManagement/RoleView'
import {
  createSystemOfMeasurementViewOptions,
  SystemOfMeasurementView,
} from '../../../../View/UsersManagement/SystemOfMeasurementView'
import { UserView } from '../../../../View/UsersManagement/UsersView'
import { Button } from '../../../Button/Button'
import { BUTTON_TYPE_SUBMIT } from '../../../Button/ButtonType'
import { ModalFooter } from '../../../Modal/Footer/ModalFooter'
import { ModalInputField } from '../../../Modal/Form/InputField/ModalInputField'
import { ModalMultiSelectField } from '../../../Modal/Form/MultiSelectField/ModalMultiSelectField'
import { ModalSelectField } from '../../../Modal/Form/SelectField/ModalSelectField'
import { ModalHeader } from '../../../Modal/Header/ModalHeader'
import { ModalContent } from '../../../Modal/ModalContent'
import { ModalSubtitle } from '../../../Modal/Subtitle/ModalSubtitle'
import { ModalTitle } from '../../../Modal/Title/ModalTitle'

import styles from './UserManagementModalForm.module.scss'

type UserManagementModalFormProps<Id = string> = Readonly<{
  user: UserView<Id>
  title: string
  roles: RoleView[]
  groups: GroupView[]
  ports: PortView[]
  locales: LocaleView[]
  onSave: (user: UserView<Id>) => void
}>

export function UserManagementModalForm<Id>(props: UserManagementModalFormProps<Id>) {
  const allRoleIds = useMemo(() => props.roles.map(r => r.id), [props.roles])
  const allGroupIds = useMemo(() => props.groups.map(g => g.id), [props.groups])
  const allPortIds = useMemo(() => props.ports.map(p => p.id), [props.ports])

  const userFormValidationSchema = useMemo(
    () =>
      object({
        firstName: string().required('Required'),
        lastName: string().required('Required'),
        email: string().required('Required').email('Invalid email'),
        blocked: boolean(),
        roles: array<RoleId>(mixed<RoleId>().oneOf(allRoleIds)).min(1, 'Required'),
        groups: array<GroupId>(mixed<GroupId>().oneOf(allGroupIds)).min(1, 'Required'),
        ports: array<Unlocode>(mixed<Unlocode>().oneOf(allPortIds)).min(1, 'Required'),
        defaultHost: string().required('Required'),
        locale: mixed<LocaleId>().required('Required'),
      }),
    [allRoleIds, allGroupIds, allPortIds]
  )

  const formPath = Path.obj<UserView<Id>>()

  return (
    <Formik<UserView<Id>>
      onSubmit={props.onSave}
      initialValues={props.user}
      validationSchema={userFormValidationSchema}
    >
      {({ handleSubmit, isValid, dirty }: FormikProps<UserView<Id>>) => (
        <Form className={styles.form}>
          <ModalHeader>
            <ModalTitle>{props.title}</ModalTitle>
            <ModalSubtitle>{PRODUCT_NAME} admin module</ModalSubtitle>
          </ModalHeader>

          <ModalContent>
            <div>
              <div className={styles.inputs}>
                <div className={styles.formRow}>
                  <div className={styles.formColumn}>
                    <ModalInputField
                      name={formPath.key('firstName')}
                      label="Name"
                      testId={TestIds.UserManagementModalFormNameInput}
                    />
                  </div>
                  <div className={styles.formColumn}>
                    <ModalInputField
                      name={formPath.key('lastName')}
                      label="Surname"
                      testId={TestIds.UserManagementModalFormSurNameInput}
                    />
                  </div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.formColumn}>
                    <ModalInputField
                      name={formPath.key('email')}
                      label="E-mail"
                      testId={TestIds.UserManagementModalFormEmailInput}
                    />
                  </div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.formColumn}>
                    <ModalMultiSelectField<UserView<Id>, GroupId, GroupView>
                      name={formPath.key('groups')}
                      label="Group(s)"
                      options={props.groups}
                      testId={TestIds.UserManagementModalFormGroupsSelect}
                    />
                  </div>
                  <div className={styles.formColumn}>
                    <ModalMultiSelectField<UserView<Id>, RoleId, RoleView>
                      label="Role(s)"
                      name={formPath.key('roles')}
                      options={props.roles}
                      testId={TestIds.UserManagementModalFormRolesSelect}
                    />
                  </div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.formColumn}>
                    <ModalMultiSelectField<UserView<Id>, Unlocode, PortView>
                      label="Port(s)"
                      name={formPath.key('ports')}
                      options={props.ports}
                      testId={TestIds.UserManagementModalFormPortsSelect}
                    />
                  </div>
                  <div className={styles.formColumn}>
                    <ModalSelectField<UserView<Id>, LocaleId, LocaleView>
                      label="Language"
                      name={formPath.key('locale')}
                      options={props.locales}
                      testId={TestIds.UserManagementModalFormLanguageSelect}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.formColumn}>
                    <ModalSelectField<UserView<Id>, SystemOfMeasurement, SystemOfMeasurementView>
                      label="System of measurement"
                      name={formPath.key('measurementUnit')}
                      options={createSystemOfMeasurementViewOptions()}
                      testId={TestIds.UserManagementModalFormMeasurementSystemSelect}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            <div className={styles.actions}>
              <Button
                onClick={handleSubmit}
                disabled={dirty ? !isValid : !dirty}
                type={BUTTON_TYPE_SUBMIT}
                testId={TestIds.UserManagementModalFormSubmitButton}
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}
