import { useMemo } from 'react'

import classnames from 'classnames'

import { Company, CompanyId, createCompanyId } from '../../../ApplicationState/Company/Company'
import { UserId } from '../../../ApplicationState/UsersManagement/User'
import { renderNoneable } from '../../../utils/rendering'
import { None } from '../../../utils/strictNull'
import { TestIds } from '../../../utils/testIds/testIds'
import { GroupView } from '../../../View/UsersManagement/GroupView'
import { LocaleView } from '../../../View/UsersManagement/LocaleView'
import { ModalEditUser, ModalNewUser } from '../../../View/UsersManagement/Modal'
import { PortView } from '../../../View/UsersManagement/PortView'
import { RoleView } from '../../../View/UsersManagement/RoleView'
import { UserView } from '../../../View/UsersManagement/UsersView'
import { Button } from '../../Button/Button'
import { ButtonIconAlignment } from '../../Button/ButtonIcon'
import { ButtonRole } from '../../Button/ButtonRole'
import { ButtonSize } from '../../Button/ButtonSize'
import { ContentPage } from '../../ManagementPage/ContentPage'
import { UserManagementModal } from '../Modal/UserManagementModal'
import { UsersManagementTable } from '../UsersManagementTable/UsersManagementTable'

import styles from './UsersManagementOverview.module.scss'

type UsersManagementOverviewProps = Readonly<{
  companies: Company[]
  selectedCompany: Company
  users: UserView[]
  roles: RoleView[]
  groups: GroupView[]
  ports: PortView[]
  locales: LocaleView[]
  modal: ModalNewUser | ModalEditUser | None
  onChangeCompany: (companyId: CompanyId) => void
  onSaveModal: (modal: ModalNewUser | ModalEditUser) => void
  onDeleteUser: (userId: UserId) => void
  onCloseModal: () => void
  onOpenNewUserModal: () => void
  onOpenEditUserModal: (user: UserView) => void
}>

export const UsersManagementOverview = (props: UsersManagementOverviewProps) => {
  const [amountActiveUsers, amountBlockedUsers] = useMemo(() => {
    const activeUsers = props.users.filter(user => !user.blocked).length
    return [activeUsers, props.users.length - activeUsers]
  }, [props.users])

  return (
    <ContentPage>
      <h1>User Management</h1>
      <div className={styles.subHeader}>
        <div className={styles.companies}>
          <select
            value={`${props.selectedCompany.id}`}
            disabled={props.companies.length < 2}
            onChange={event => props.onChangeCompany(createCompanyId(event.target.value))}
            className={styles.companyFilter}
          >
            {props.companies.map(company => (
              <option key={company.id.toString()} value={company.id.toString()}>
                {company.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.userControls}>
          <div className={styles.statusCounters}>
            <div className={styles.counter}>
              <span>Active users</span>
              <span className={classnames(styles.value, styles.active)}>{amountActiveUsers}</span>
            </div>
            <div className={styles.counter}>
              <span>Blocked users</span>
              <span className={classnames(styles.value, styles.error)}>{amountBlockedUsers}</span>
            </div>
          </div>
          <Button
            size={ButtonSize.Medium}
            onClick={props.onOpenNewUserModal}
            role={ButtonRole.Primary}
            materialIcon={{ type: 'add', alignment: ButtonIconAlignment.Right }}
            testId={TestIds.UserManagementNewUserButton}
          >
            Add new user
          </Button>
        </div>
      </div>
      <UsersManagementTable users={props.users} onEditUser={props.onOpenEditUserModal} />
      {renderNoneable(props.modal, modal => (
        <UserManagementModal
          roles={props.roles}
          groups={props.groups}
          ports={props.ports}
          locales={props.locales}
          modal={modal}
          onClose={props.onCloseModal}
          onDelete={props.onDeleteUser}
          onSave={props.onSaveModal}
        />
      ))}
    </ContentPage>
  )
}
