import React from 'react'

import classnames from 'classnames'

import { SideSubMenuForm } from '../SideSubMenu/SideSubMenuForm/SideSubMenuForm'
import {
  SideSubMenuFormState,
  FORM_STATE_SUBMITTING,
  FORM_STATE_SUBMITTED,
} from '../SideSubMenu/SideSubMenuForm/SideSubMenuFormState'
import { SideSubMenuFormItem } from '../SideSubMenu/SideSubMenuFormItem/SideSubMenuFormItem'
import { SideSubMenuInputText } from '../SideSubMenu/SideSubMenuInputText/SideSubMenuInputText'
import { SideSubMenuInputTextarea } from '../SideSubMenu/SideSubMenuInputTextarea/SideSubMenuInputTextarea'

import {
  AttachedFileStatus,
  NO_ATTACHED_FILE_SELECTED,
  ATTACHED_FILE_OK,
  ATTACHED_FILE_ERROR,
} from './AttachedFileStatus'
import styles from './BugReporter.module.scss'
import { IBugReporterForm } from './IBugReporterForm'

interface IBugReporterFormProps {
  form: IBugReporterForm
  formState: SideSubMenuFormState
  attachedFileStatus: AttachedFileStatus
  onChange: (form: Partial<IBugReporterForm>) => void
}

function renderSubtitle(status: AttachedFileStatus) {
  const generalInstructions = (
    <div className={styles.subtitle}>Attach an image to clarify your bug report. (Supports: JPG, PNG, PDF)</div>
  )

  if (status.type === NO_ATTACHED_FILE_SELECTED) {
    return generalInstructions
  }
  if (status.type === ATTACHED_FILE_OK) {
    return (
      <div className={styles.statusMessage}>
        <div className={styles.statusText}>Uploaded: {status.fileName}</div>
        <div className={classnames(styles.statusIcon, styles.ok)} />
      </div>
    )
  }
  if (status.type === ATTACHED_FILE_ERROR) {
    return (
      <>
        <div className={styles.statusMessage}>
          <div className={styles.statusText}>{status.message}</div>
          <div className={classnames(styles.statusIcon, styles.error)} />
        </div>
        {generalInstructions}
      </>
    )
  }
  const exhaustive: never = status
  throw new Error(exhaustive)
}

type InputFileProps = Readonly<{
  onChange: (file: File | false) => void
  attachedFileStatus: AttachedFileStatus
  disabled: boolean
}>

const InputFile = (props: InputFileProps) => {
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | false = e.target.files && e.target.files.length > 0 ? e.target.files[0] : false
    props.onChange(file)
    e.target.value = ''
  }
  return (
    <label className={styles.attachedFileLabel}>
      <div className={styles.iconColumn} />
      <div className={styles.descriptionColumn}>
        <div className={styles.title}>ATTACH FILE (OPTIONAL)</div>
        {renderSubtitle(props.attachedFileStatus)}
      </div>
      <input
        className={styles.hiddenFileInput}
        type="file"
        accept=".gif,.jpg,.jpeg,.png,.pdf,.bmp"
        onChange={onChangeFile}
        disabled={props.disabled}
      />
    </label>
  )
}

export const BugReporterForm = (props: IBugReporterFormProps) => {
  const formInputsDisabled = props.formState === FORM_STATE_SUBMITTING || props.formState === FORM_STATE_SUBMITTED
  const onSubjectChange = (subject: string) => {
    props.onChange({ subject })
  }
  const onDescriptionChange = (description: string) => {
    props.onChange({ description })
  }
  const onFileChange = (attachedFile: File | false) => {
    props.onChange({ attachedFile })
  }

  return (
    <SideSubMenuForm>
      <h3 className={styles.heading}>Report a bug</h3>
      <SideSubMenuFormItem>
        <SideSubMenuInputText
          label="SUBJECT"
          value={props.form.subject}
          onChange={onSubjectChange}
          disabled={formInputsDisabled}
        />
      </SideSubMenuFormItem>
      <SideSubMenuFormItem>
        <SideSubMenuInputTextarea
          label="BUG DESCRIPTION"
          value={props.form.description}
          onChange={onDescriptionChange}
          disabled={formInputsDisabled}
        />
      </SideSubMenuFormItem>
      <SideSubMenuFormItem>
        <InputFile
          onChange={onFileChange}
          attachedFileStatus={props.attachedFileStatus}
          disabled={formInputsDisabled}
        />
      </SideSubMenuFormItem>
    </SideSubMenuForm>
  )
}
