import { SideSubMenuCancelButton } from '../SideSubMenuCancelButton/SideSubMenuCancelButton'
import {
  SideSubMenuFormState,
  FORM_STATE_SUBMITTING,
  FORM_STATE_INITIAL,
  FORM_STATE_SUBMITTED,
  FORM_STATE_SUBMIT_ERROR,
} from '../SideSubMenuForm/SideSubMenuFormState'
import { SideSubMenuSubmitButton } from '../SideSubMenuSubmitButton/SideSubMenuSubmitButton'

import styles from './SideSubMenuFormButtons.module.scss'

interface ISideSubMenuFormButtons {
  isValid: boolean
  formState: SideSubMenuFormState

  onSubmit: () => void
  onCancel: () => void
}

export const SideSubMenuFormButtons = ({ formState, isValid, onSubmit, onCancel }: ISideSubMenuFormButtons) => {
  const isCancelButtonVisible: Record<SideSubMenuFormState, boolean> = {
    [FORM_STATE_INITIAL]: true,
    [FORM_STATE_SUBMITTING]: false,
    [FORM_STATE_SUBMITTED]: false,
    [FORM_STATE_SUBMIT_ERROR]: true,
  }

  return (
    <div className={styles.buttons}>
      <SideSubMenuSubmitButton onSubmit={onSubmit} isFormValid={isValid} formState={formState} />
      <SideSubMenuCancelButton onCancel={onCancel} visible={isCancelButtonVisible[formState]} />
    </div>
  )
}
