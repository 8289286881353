import { GroupId } from '../../ApplicationState/UsersManagement/Group'
import { LocaleId } from '../../ApplicationState/UsersManagement/Locale'
import { RoleId } from '../../ApplicationState/UsersManagement/Role'
import { User, UserId } from '../../ApplicationState/UsersManagement/User'
import { PRONTO_BACKEND_URL } from '../../config'
import { Unlocode } from '../../Domain/NauticalLocations/IPort'
import { SystemOfMeasurement } from '../../Domain/Users/ClientPreferences/SystemOfMeasurement'
import { None, none } from '../../utils/strictNull'

import { DEFAULT_LOCALE_ID } from './constants'

export type UserView<Id = UserId> = Readonly<{
  id: Id
  firstName: string
  lastName: string
  email: string
  blocked: boolean
  roles: RoleId[]
  groups: GroupId[]
  ports: Unlocode[]
  defaultHost: string
  locale: LocaleId
  measurementUnit: SystemOfMeasurement
}>

export type NewUserView = UserView<None>
export type EditUserView = UserView<UserId>

export const getNewUserView = (): NewUserView => ({
  id: none,
  firstName: '',
  lastName: '',
  email: '',
  blocked: false,
  roles: [],
  groups: [],
  ports: [],
  defaultHost: new URL(PRONTO_BACKEND_URL).hostname,
  locale: DEFAULT_LOCALE_ID,
  measurementUnit: SystemOfMeasurement.Metric,
})

export const toUserViewFromUser = (user: User): UserView => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    blocked: user.blocked,
    roles: user.roles,
    groups: user.groups,
    ports: user.ports,
    defaultHost: user.defaultHost,
    locale: user.locale,
    measurementUnit: user.measurementUnit,
  }
}
