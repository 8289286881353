import { useEffect, useState } from 'react'

import { Company } from '../../ApplicationState/Company/Company'
import { CompanyManagementOverview as PresentationalComponent } from '../../components/CompanyManagement/Overview/CompanyManagementOverview'
import { CompanyManagementWarning } from '../../components/CompanyManagement/Warning/CompanyManagementWarning'
import { FullLoadingLoader } from '../../components/Loader/FullLoadingLoader/FullLoadingLoader'
import { DATE_RANGE_OFFSET_OPTIONS, DATE_RANGE_START_OPTIONS } from '../../constants'
import { IDateRangeOptions } from '../../store/filters/filters.interfaces'
import { notFoundStatusCode, NotFoundStatusCode } from '../../utils/NotFound'
import { Strings } from '../../utils/str'
import { None, none } from '../../utils/strictNull'

import { fetchCompanies } from './fetchCompanies'
import { updateCompany } from './updateCompany'

export const CompanyManagementOverview = () => {
  const [companyList, setCompanies] = useState<Company[] | None | NotFoundStatusCode>(none)
  const [editingCompany, setEditingCompany] = useState<Company | None>(none)
  const [errorMessage, setErrorMessage] = useState<string | None>(none)
  const dateRangeOptions: IDateRangeOptions = {
    startOptions: DATE_RANGE_START_OPTIONS,
    offsetOptions: DATE_RANGE_OFFSET_OPTIONS,
  }

  useEffect(() => {
    updateCompanies()
  }, [])

  function updateCompanies() {
    fetchCompanies()
      .then(companies => setCompanies(companies.sort((a, b) => Strings.localeCompare(a.name, b.name))))
      .catch(() => setCompanies(notFoundStatusCode))
  }

  function handleOnSave(company: Company) {
    updateCompany(company)
      .then(() => {
        setEditingCompany(none)
        setErrorMessage(none)
        updateCompanies()
      })
      .catch(() => setErrorMessage('Error while updating company, please try again later'))
  }

  if (companyList === none) {
    return <FullLoadingLoader />
  }
  if (companyList === notFoundStatusCode) {
    return <CompanyManagementWarning />
  }

  return (
    <PresentationalComponent
      companies={companyList}
      dateRangeOptions={dateRangeOptions}
      editingCompany={editingCompany}
      onEdit={setEditingCompany}
      onCloseModal={() => setEditingCompany(none)}
      onSaveModal={handleOnSave}
      errorMessage={errorMessage}
    />
  )
}
