import MaterialIcon from '../../MaterialIcon/MaterialIcon'

import styles from './SideSubMenuHeader.module.scss'

type SideSubMenuHeaderProps = Readonly<{
  onBack: () => void
}>

export const SideSubMenuHeader = (props: SideSubMenuHeaderProps) => (
  <div className={styles.header}>
    <MaterialIcon type="arrow_back" onClick={props.onBack} className={styles.backButton} />
  </div>
)
