import { Column, createColumn } from './Column'

export type FilterableColumn<Id extends string | number, Value, Node, AdditionalProps> = Column<
  Id,
  Value,
  Node,
  AdditionalProps
> &
  Readonly<{
    filterValues(values: Value[], filter: string): Value[]
  }>

export function createFilterableColumn<Id extends string | number, Outer, Inner, Node, AdditionalProps>(
  id: Id,
  header: string,
  accessor: (outer: Outer) => Inner,
  formatter: (inner: Inner, outer: Outer, additionalProps: AdditionalProps) => Node,
  stringRepresentation: (inner: Inner) => string,
  trackingCategory?: string
): FilterableColumn<Id, Outer, Node, AdditionalProps> {
  return {
    ...createColumn(id, header, accessor, formatter, trackingCategory),
    filterValues(outers: Outer[], filter: string) {
      return outers.filter(outer => {
        return stringRepresentation(accessor(outer)).toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      })
    },
  }
}
