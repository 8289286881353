import { toUserFromUserJson } from '../../Api/Users/UserJson'
import { CompanyId } from '../../ApplicationState/Company/Company'
import { User } from '../../ApplicationState/UsersManagement/User'
import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { lift } from '../../utils/arr'
import { getAuthToken } from '../../utils/auth/authClientObservable'

export async function fetchUsers(companyId: CompanyId): Promise<User[]> {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/${companyId}/users`

  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }

  return fetch(url, options).then((reponse: Response): Promise<User[]> => {
    return handleJSONResponse(options, reponse).then(lift(toUserFromUserJson))
  })
}
