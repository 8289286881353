import * as React from 'react'

import { Field, FastField } from 'formik'

import { Path } from '../../utils/Path'
import { StrictUndefined } from '../../utils/strictNull'

import { FieldProps } from './FieldProps'

type FormFieldProps<P, C> = Readonly<{
  name: Path<P, C>
  validate?: (c: C) => undefined | string | Promise<undefined | string>
  children: (fieldProps: FieldProps<P, C>) => React.ReactNode
  component?: typeof Field | typeof FastField
}>

// This component is a wrapper around Formik Field component aimed to provide a better type safety
// We use the Path util for the field name
export function FormField<P, C>(props: FormFieldProps<P, C>) {
  const Component = StrictUndefined.orElse(props.component, Field)
  return (
    <Component name={props.name.totalPath} validate={props.validate}>
      {(fieldProps: FieldProps<P, C>) => props.children(fieldProps)}
    </Component>
  )
}
