import { RoleId, createRoleId } from '../../ApplicationState/UsersManagement/Role'

export type RoleView = Readonly<{
  id: RoleId
  name: string
}>

export function toRoleView(role: string): RoleView {
  return {
    id: createRoleId(role),
    name: role,
  }
}
