import { Button } from '../SideSubMenuButtons/SideSubMenuButtons'

import styles from './SideSubMenuCancelButton.module.scss'

type SideSubMenuCancelButtonProps = Readonly<{
  onCancel: () => void
  visible: boolean
}>

export const SideSubMenuCancelButton = ({ onCancel, visible }: SideSubMenuCancelButtonProps) =>
  visible ? (
    <Button
      onClick={onCancel}
      theme={{
        button: styles.cancelButton,
      }}
    >
      Cancel
    </Button>
  ) : null
