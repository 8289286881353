import { createLocaleId, LocaleId } from '../../ApplicationState/UsersManagement/Locale'
import { ILocale } from '../../shared/interfaces/settings/Settings'

export type LocaleView = Readonly<{
  id: LocaleId
  name: string
}>

export function toLocaleView(locale: ILocale): LocaleView {
  return {
    id: createLocaleId(locale.value),
    name: locale.displayName,
  }
}
