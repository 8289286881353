import classnames from 'classnames'

import MaterialIcon from '../../MaterialIcon/MaterialIcon'

import styles from './CornerCloseButton.module.scss'

const CORNER_CLOSE_BUTTON_POSITION_TOP_LEFT = 'CORNER_CLOSE_BUTTON_POSITION_TOP_LEFT'
export const CORNER_CLOSE_BUTTON_POSITION_TOP_RIGHT = 'CORNER_CLOSE_BUTTON_POSITION_TOP_RIGHT'
const CORNER_CLOSE_BUTTON_POSITION_BOTTOM_LEFT = 'CORNER_CLOSE_BUTTON_POSITION_BOTTOM_LEFT'
const CORNER_CLOSE_BUTTON_POSITION_BOTTOM_RIGHT = 'CORNER_CLOSE_BUTTON_POSITION_BOTTOM_RIGHT'

type CornerCloseButtonPosition =
  | typeof CORNER_CLOSE_BUTTON_POSITION_TOP_LEFT
  | typeof CORNER_CLOSE_BUTTON_POSITION_TOP_RIGHT
  | typeof CORNER_CLOSE_BUTTON_POSITION_BOTTOM_LEFT
  | typeof CORNER_CLOSE_BUTTON_POSITION_BOTTOM_RIGHT

interface ICornerCloseButtonProps {
  onClose: () => void
  className?: string
  position?: CornerCloseButtonPosition
}

const getPositionClassNames = (position: CornerCloseButtonPosition) => {
  switch (position) {
    case CORNER_CLOSE_BUTTON_POSITION_TOP_LEFT:
      return [styles.top, styles.left]
    case CORNER_CLOSE_BUTTON_POSITION_TOP_RIGHT:
      return [styles.top, styles.right]
    case CORNER_CLOSE_BUTTON_POSITION_BOTTOM_LEFT:
      return [styles.bottom, styles.left]
    case CORNER_CLOSE_BUTTON_POSITION_BOTTOM_RIGHT:
      return [styles.top, styles.right]
    default:
      return []
  }
}

export const CornerCloseButton = (props: ICornerCloseButtonProps) => (
  <div
    className={classnames([
      styles.closeIcon,
      ...getPositionClassNames(props.position || CORNER_CLOSE_BUTTON_POSITION_TOP_RIGHT),
      props.className || null,
    ])}
    onClick={props.onClose}
  >
    <MaterialIcon className={styles.cross} type="close" />
  </div>
)
