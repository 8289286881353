export const identity = <A>(a: A) => a

export const always =
  <A>(a: A) =>
  () =>
    a

export function compose<A extends any[], B, C>(l: (...b: A) => B, r: (a: B) => C): (...a: A) => C {
  return (...a: A) => r(l(...a))
}

export function noOp(): void {}
