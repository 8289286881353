import React from 'react'

import { setStateAsync, after } from '../../utils/promise'
import {
  SideSubMenuFormState,
  FORM_STATE_INITIAL,
  FORM_STATE_SUBMITTING,
  FORM_STATE_SUBMITTED,
  FORM_STATE_SUBMIT_ERROR,
} from '../SideSubMenu/SideSubMenuForm/SideSubMenuFormState'

import {
  AttachedFileStatus,
  NO_ATTACHED_FILE_SELECTED,
  ATTACHED_FILE_ERROR,
  ATTACHED_FILE_OK,
} from './AttachedFileStatus'
import { BugReporter as PresentationalComponent } from './BugReporter'
import { createBugReport } from './IBugReport'
import { IBugReporterForm } from './IBugReporterForm'
import { postBugReport } from './postBugReport'

const TIME_TO_SHOW_SUCCESS_MESSAGE = 2500

interface IBugReporter {
  email?: string
  onBack: () => void
}

interface IBugReporterState {
  bugReportState: SideSubMenuFormState
  form: IBugReporterForm
  attachedFileStatus: AttachedFileStatus
}

const defaultForm: IBugReporterForm = {
  subject: '',
  description: '',
  attachedFile: false,
}

const defaultState: IBugReporterState = {
  bugReportState: FORM_STATE_INITIAL,
  form: defaultForm,
  attachedFileStatus: { type: NO_ATTACHED_FILE_SELECTED },
}

/**
 * We need some extra control because we're doing some stuff outside of the Redux actions.
 */
export class BugReporter extends React.Component<IBugReporter, IBugReporterState> {
  constructor(props: IBugReporter) {
    super(props)
    this.state = defaultState
  }

  public render() {
    return (
      <PresentationalComponent
        form={this.state.form}
        attachedFileStatus={this.state.attachedFileStatus}
        bugReportState={this.state.bugReportState}
        formIsValid={this.isValid(this.state.form)}
        onFormChange={this.onFormChange}
        onBack={this.onCancel}
        onSubmit={this.onSubmit}
      />
    )
  }

  private onSubmit = async () => {
    this.setState({
      bugReportState: FORM_STATE_SUBMITTING,
    })
    try {
      const attachedFile = await this.readFile(this.state.form.attachedFile)
      const bugReport = createBugReport(
        this.state.form.subject,
        this.state.form.description,
        attachedFile,
        this.props.email
      )
      await postBugReport(bugReport)
      await setStateAsync<Pick<IBugReporterState, 'bugReportState'>>(this, {
        bugReportState: FORM_STATE_SUBMITTED,
      })
      await after(null, TIME_TO_SHOW_SUCCESS_MESSAGE)
      await setStateAsync(this, defaultState)
      this.props.onBack()
    } catch (err) {
      await setStateAsync<Pick<IBugReporterState, 'bugReportState'>>(this, {
        bugReportState: FORM_STATE_SUBMIT_ERROR,
      })
    }
  }

  private onCancel = () => {
    this.setState(defaultState)
    this.props.onBack()
  }

  private readFile(file: File | false): Promise<string | ArrayBuffer | false> {
    if (!file) {
      return Promise.resolve<string | false>(false)
    }

    const fileReader = new FileReader()
    const fileLoaded = new Promise<string | ArrayBuffer | false>(resolve => {
      fileReader.addEventListener('load', () => {
        const result = fileReader.result !== null ? fileReader.result : false
        resolve(result)
      })
    })
    fileReader.readAsDataURL(file)
    return fileLoaded
  }

  private onFormChange = (fields: Partial<IBugReporterForm>) => {
    if (fields.attachedFile !== undefined) {
      this.setState({
        attachedFileStatus: this.validateAttachedFile(fields.attachedFile),
      })
    }

    this.setState(prev => ({
      form: { ...prev.form, ...fields },
    }))
  }

  private isValid(form: IBugReporterForm): boolean {
    return (
      !!form.subject.trim() &&
      !!form.description.trim() &&
      this.validateAttachedFile(form.attachedFile).type !== ATTACHED_FILE_ERROR
    )
  }

  private validateAttachedFile(file: File | false): AttachedFileStatus {
    if (file === false) {
      return { type: NO_ATTACHED_FILE_SELECTED }
    }

    // Max: 2 MB
    if (file.size > 2 * 1000 * 1000) {
      return { type: ATTACHED_FILE_ERROR, message: 'FILE TOO LARGE' }
    }

    const allowedTypes = ['image/jpeg', 'image/gif', 'image/png', 'image/bmp', 'image/x-windows-bmp', 'application/pdf']
    if (allowedTypes.indexOf(file.type) === -1) {
      return { type: ATTACHED_FILE_ERROR, message: 'WRONG FILE TYPE' }
    }

    return { type: ATTACHED_FILE_OK, fileName: file.name }
  }
}
