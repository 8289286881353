import { AuthorizationJson } from '../../Api/Users/AuthorizationJson'
import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { getAuthToken } from '../../utils/auth/authClientObservable'

export async function fetchAssignableRolesAndGroups(): Promise<AuthorizationJson> {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/users/me/assignableGroupsAndRoles`

  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }

  return fetch(url, options).then((reponse: Response): Promise<AuthorizationJson> => {
    return handleJSONResponse(options, reponse)
  })
}
