import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { LoadingLoader } from '../../components/Loader/Loader'
import { authClientSubject } from '../../utils/auth/authClientObservable'
import { IUser } from '../../shared/interfaces/settings/Settings'
import { fetchProntoUser } from '../../shared/rest/fetchProntoUser'
import { onGlobalError } from '../../middlewares/errorLogger'

type RenderProps = { user: IUser; accessToken: string; idToken: string }

const WithUserCredentials: React.FC<{
  children: (props: RenderProps) => JSX.Element
}> = ({ children }) => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0()

  const [{ isLoading, user, accessToken, idToken }, setState] = useState<{ isLoading: boolean } & Partial<RenderProps>>(
    { isLoading: true }
  )

  useEffect(() => {
    Promise.all([getIdTokenClaims(), getAccessTokenSilently()])
      .then(([claims, accessToken]) =>
        fetchProntoUser(claims!.__raw, accessToken).then(user => ({
          user,
          accessToken,
          idToken: claims!.__raw,
        }))
      )
      .then(res => setState({ ...res, isLoading: false }))
  }, [getIdTokenClaims, getAccessTokenSilently])

  return isLoading || user === undefined || accessToken === undefined || idToken === undefined ? (
    <LoadingLoader />
  ) : (
    children({ user, accessToken, idToken })
  )
}

export const IsAuthenticated: React.FC<{ children: (props: RenderProps) => JSX.Element; fallback: JSX.Element }> = ({
  children,
  fallback,
}) => {
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      // Provide api endpoints with a way to get the accesToken
      authClientSubject.next({
        isAuthenticated: true,
        getTokenSilently: getAccessTokenSilently,
      })
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently])

  useEffect(() => {
    if (error !== undefined && error instanceof Error) {
      onGlobalError({ reference: 'Error with authentication', error }, 'IsAuthenticated.tsx')
    }
  }, [error])

  if (isLoading) {
    return <LoadingLoader />
  }

  return isAuthenticated && error === undefined ? <WithUserCredentials>{children}</WithUserCredentials> : fallback
}
