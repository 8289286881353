import { IDateRange } from '../../shared/interfaces/filters/IFilters'

export type DateRangeJson = Readonly<{
  start: number
  end: number
  unit: 'day' | 'hour' | 'minute'
}>

export function dateRangeFromJson({ start, end, unit }: DateRangeJson): IDateRange {
  return {
    start,
    end,
    unit,
  }
}

export function dateRangeJsonFromDateRange({ start, end, unit }: IDateRange): DateRangeJson {
  return {
    start,
    end,
    unit,
  }
}
