import { Company, createCompanyId } from '../../ApplicationState/Company/Company'

import { dateRangeFromJson, DateRangeJson } from './DateRangeJson'

export type CompanyJson = Readonly<{
  id: string
  name: string
  acceptedTerms: boolean
  allowedDateRange: DateRangeJson
}>

export const toCompanyFromCompanyJson = (json: CompanyJson): Company => ({
  id: createCompanyId(json.id),
  name: json.name,
  acceptedTerms: json.acceptedTerms,
  allowedDateRange: dateRangeFromJson(json.allowedDateRange),
})
