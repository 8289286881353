import classnames from 'classnames'

import { ILoaderState, LOADING, LOADING_ERROR, LOADING_FINISHED } from '../../store/ui/interfaces/ILoaderState'
import { throwUnreachable } from '../../utils/throwUnreachable'

import styles from './Loaders.module.scss'

interface ILoaderProps {
  containerClassName?: string
  spinnerClassName?: string
  state: ILoaderState
  loaderVisible?: boolean
  theme?: LoaderTheme
}

export enum LoaderTheme {
  Light,
  Dark,
}

function getMessage(state: ILoaderState): string {
  if (state.type === LOADING) {
    return state.customMessage || 'LOADING'
  }
  if (state.type === LOADING_FINISHED) {
    return state.customMessage || 'DONE'
  }
  if (state.type === LOADING_ERROR) {
    return state.customMessage || 'ERROR'
  }
  return throwUnreachable(state.type)
}

export const Loader = (props: ILoaderProps) => {
  const { theme = LoaderTheme.Light } = props

  // The `=== false` makes sure that the component is also rendered
  // when no `loaderVisible` is specified (`loaderVisible === undefined`)
  if (props.loaderVisible === false) {
    return null
  }

  return (
    <div className={props.containerClassName}>
      <div
        className={classnames(styles.loader, {
          [styles.loading]: props.state.type === LOADING,
          [styles.error]: props.state.type === LOADING_ERROR,
          [styles.finished]: props.state.type === LOADING_FINISHED,
          [styles.light]: theme === LoaderTheme.Light,
          [styles.dark]: theme === LoaderTheme.Dark,
        })}
      >
        <div className={classnames(styles.spinnerWrapper, props.spinnerClassName || null)}>
          <div className={styles.icon} />
          <div className={styles.message}>{getMessage(props.state)}</div>
        </div>
      </div>
    </div>
  )
}

export const LoadingLoader = () => <Loader state={{ type: LOADING }} />
