import { PureComponent } from 'react'

import classnames from 'classnames'
import { createSelector } from 'reselect'

import { includes } from '../../utils/arr'
import { always } from '../../utils/functions'
import { keys } from '../../utils/obj'
import { renderIf } from '../../utils/rendering'
import { none, StrictUndefined } from '../../utils/strictNull'
import { TestIds } from '../../utils/testIds/testIds'
import { SortDirection, toggleSortDirection, getSortDirectionSymbol } from '../../View/Sorting/SortDirection'
import { Column } from '../../View/Tables/Column'
import { FilterableColumn } from '../../View/Tables/FilterableColumn'
import { SortableColumn } from '../../View/Tables/SortableColumn'
import { VirtualTable, VirtualTableTheme } from '../Dashboards/OperationalDashboard/VirtualTable/VirtualTable'

import { ColumnHeaderFilterInput } from './ColumnHeaderFilterInput/ColumnHeaderFilterInput'
import styles from './Table.module.scss'
import { bodyRowHeight, headerRowHeight } from './variables'

export type TableConfigColumns<
  ColumnType extends string | number,
  SortableColumnType extends string | number,
  FilterableColumnType extends string | number,
  Value,
  Node,
  AdditionalProps
> = { [K in SortableColumnType]: SortableColumn<K, Value, Node, AdditionalProps> } & {
  [K in FilterableColumnType]: FilterableColumn<K, Value, Node, AdditionalProps>
} & { [K in ColumnType]: Column<K, Value, Node, AdditionalProps> }

type TableConfig<
  ColumnType extends string | number,
  SortableColumnType extends string | number,
  FilterableColumnType extends string | number,
  Value,
  Node,
  AdditionalProps
> = Readonly<{
  // So we can get a unique key for each cell in a row:
  getKey: (value: Value, index: number) => string | number

  // So we know how to format the cells (and possibly even sort/filter them):
  columns: TableConfigColumns<ColumnType, SortableColumnType, FilterableColumnType, Value, Node, AdditionalProps>

  // So we know in which order you want the columns to appear:
  allColumnTypes: Array<ColumnType | SortableColumnType | FilterableColumnType>

  // So we know which headers have extra options:
  sortableColumnTypes: SortableColumnType[]
  filterableColumnTypes: FilterableColumnType[]

  // So we know how to sort the table intially:
  initialSortedBy?: SortableColumnType

  // So we know how the table looks:
  styles: Partial<Record<ColumnType | SortableColumnType | FilterableColumnType, string>>
  rowWidth: number

  // Some behavior:
  onClick?: (value: Value, index: number, additionalProps: AdditionalProps) => void

  testId?: TestIds
}>

export type TableTheme = Partial<{
  table: string
  header: string
  body: string
  row: string
  cell: string
  emptySpaceRight: string
}>

type TableProps<A> = Readonly<{
  theme?: TableTheme
  values: A[]
}>

type TableState<SortableColumnType extends string | number, FilterableColumnType extends string | number> = Readonly<{
  sortedBy?: SortableColumnType
  sortDirection: SortDirection
  filters: Partial<Record<FilterableColumnType, string>>
}>

export function createTable<
  ColumnType extends string | number,
  SortableColumnType extends string | number,
  FilterableColumnType extends string | number,
  Value,
  Node,
  AdditionalProps
>(config: TableConfig<ColumnType, SortableColumnType, FilterableColumnType, Value, Node, AdditionalProps>) {
  const sortedBySelector = (state: TableState<SortableColumnType, FilterableColumnType>) => state.sortedBy
  const sortDirectionSelector = (state: TableState<SortableColumnType, FilterableColumnType>) => state.sortDirection
  const filtersSelector = (state: TableState<SortableColumnType, FilterableColumnType>) => state.filters
  const valuesSelector = (state: TableState<SortableColumnType, FilterableColumnType>, props: TableProps<Value>) =>
    props.values
  const sortedValuesSelector = createSelector(
    sortedBySelector,
    sortDirectionSelector,
    filtersSelector,
    valuesSelector,
    (sortedBy, sortDirection, filters, values) => {
      const sortedValues = StrictUndefined.fold(
        sortedBy,
        sb => {
          const sortedByColumn = config.columns[sb]
          return sortedByColumn.sortValues(values, sortDirection)
        },
        values
      )

      const sortedAndFilteredValues = keys(filters).reduce((acc, filterableColumnType) => {
        const filter: string | undefined = filters[filterableColumnType]
        if (filter === undefined) {
          return acc
        }

        const filteredColumn = config.columns[filterableColumnType]
        return filteredColumn.filterValues(acc, filter)
      }, sortedValues)

      return sortedAndFilteredValues
    }
  )

  const itemHeight = always(bodyRowHeight)

  return class extends PureComponent<
    TableProps<Value> & AdditionalProps,
    TableState<SortableColumnType, FilterableColumnType>
  > {
    constructor(props: TableProps<Value> & AdditionalProps) {
      super(props)
      this.state = {
        sortedBy: config.initialSortedBy,
        sortDirection: SortDirection.ASC,
        filters: {},
      }
    }

    public render() {
      const { props, state } = this
      const sortedValues = sortedValuesSelector(state, props)
      const theme: TableTheme = props.theme || {}
      const virtualTableTheme: VirtualTableTheme = {
        virtualTable: classnames(styles.table, theme.table),
      }
      return (
        <VirtualTable
          theme={virtualTableTheme}
          headerHeight={headerRowHeight}
          itemHeight={itemHeight}
          minRightWidth={config.rowWidth}
          leftWidth={0}
          scrollToIndex={none}
          overscanCount={5}
          numberOfItems={sortedValues.length}
          headerRight={
            <div
              className={classnames(styles.header, theme.header, styles.row, theme.row)}
              data-test-id={TestIds.TableHeaderRow}
            >
              {config.allColumnTypes.map(columnType => {
                const column = config.columns[columnType]
                if (includes(config.sortableColumnTypes, columnType)) {
                  const sortedByThisColumn = state.sortedBy === columnType
                  const sortDirectionSymbol = getSortDirectionSymbol(state.sortDirection)
                  return (
                    <div
                      className={classnames(styles.cell, theme.cell, styles.sortable, config.styles[columnType])}
                      key={column.id}
                      onClick={() => this.setSortedBy(columnType)}
                    >
                      {column.header} {renderIf(sortedByThisColumn, sortDirectionSymbol)}
                    </div>
                  )
                }
                if (includes(config.filterableColumnTypes, columnType)) {
                  const filter: string | undefined = state.filters[columnType]
                  return (
                    <div
                      className={classnames(styles.cell, theme.cell, config.styles[columnType])}
                      key={column.id}
                      data-test-id={`${columnType}FilterInput`}
                    >
                      <ColumnHeaderFilterInput
                        onChange={e => this.setFilter(columnType, e.target.value)}
                        filter={filter || ''}
                        header={column.header}
                        trackingCategory={column.trackingCategory}
                      />
                    </div>
                  )
                }
                return (
                  <div className={classnames(styles.cell, theme.cell, config.styles[columnType])} key={column.id}>
                    {column.header}
                  </div>
                )
              })}
              <div className={classnames(styles.cell, theme.cell, theme.emptySpaceRight)} />
            </div>
          }
          renderItemBodyRight={(index, top) => {
            const value = sortedValues[index]
            const isEven = index % 2 === 0
            const evenOddClassName = isEven ? styles.even : styles.odd
            return (
              <div
                style={{ top }}
                className={classnames(styles.body, theme.body, evenOddClassName, styles.row, theme.row)}
                key={config.getKey(value, index)}
                onClick={() => {
                  if (config.onClick !== undefined) {
                    config.onClick(value, index, props)
                  }
                }}
                data-test-id={TestIds.TableBodyRow}
              >
                {config.allColumnTypes.map(columnType => {
                  const column = config.columns[columnType]
                  return (
                    <div className={classnames(styles.cell, theme.cell, config.styles[columnType])} key={column.id}>
                      {column.getValue(value, props)}
                    </div>
                  )
                })}
              </div>
            )
          }}
        />
      )
    }

    private setSortedBy = (columnType: SortableColumnType) => {
      if (this.state.sortedBy === columnType) {
        this.setState(s => ({ sortDirection: toggleSortDirection(s.sortDirection) }))
      } else {
        this.setState({ sortedBy: columnType, sortDirection: SortDirection.ASC })
      }
    }

    private setFilter = (columnType: FilterableColumnType, filter: string) => {
      if (filter === '') {
        this.setState(prevState => {
          const newFilters = { ...prevState.filters }
          delete newFilters[columnType]
          return { filters: newFilters }
        })
      } else {
        this.setState(prevState => ({ filters: { ...prevState.filters, [columnType]: filter } }))
      }
    }
  }
}
