import classnames from 'classnames'

import { UserId } from '../../../../ApplicationState/UsersManagement/User'
import { TestIds } from '../../../../utils/testIds/testIds'
import { Toggle } from '../../../Toggle/Toggle'

import styles from './UserManagementModalSidePanel.module.scss'

type UserManagementModalSidePanelProps = Readonly<{
  userId: UserId
  isBlocked: boolean
  onToggleBlocked: () => void
  onDeleteUser: (userId: UserId) => void
}>

export const UserManagementModalSidePanel = (props: UserManagementModalSidePanelProps) => (
  <div className={styles.sidePanel}>
    <div className={styles.sidePanelItem}>
      <div className={styles.explanation}>
        <div className={styles.title}>Delete account</div>
        <div className={styles.text}>Use the link below to permanently delete this user account.</div>
      </div>
      <button
        type="button"
        className={classnames(styles.action, styles.deleteAccount)}
        onClick={() => {
          props.onDeleteUser(props.userId)
        }}
        data-test-id={TestIds.UserManagementModalFormDeleteButton}
      >
        Delete account
      </button>
    </div>
    <div className={styles.sidePanelItem}>
      <div className={classnames(styles.action, styles.deactivateAccount)}>
        <span>Deactivate account</span>
        <Toggle showLabel isChecked={props.isBlocked} onToggle={props.onToggleBlocked} />
      </div>
    </div>
  </div>
)
