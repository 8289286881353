import * as React from 'react'

function delay<A>(p: Promise<A>, timeoutInMilliseconds: number): Promise<A> {
  return p.then(value => {
    return new Promise<A>(resolve => {
      setTimeout(() => {
        resolve(value)
      }, timeoutInMilliseconds)
    })
  })
}

export function after<A>(value: A, timeoutInMilliseconds: number): Promise<A> {
  return delay(Promise.resolve(value), timeoutInMilliseconds)
}

/**
 * The React version of `setState` let's you supply a callback.
 * This is quite old-fashioned (the new preference is to work with Promises).
 * This function transforms the callback-style `setState` to one that is `Promise`-based.
 */
export function setStateAsync<S>(component: React.Component<any, S>, state: S): Promise<S> {
  return new Promise<S>(resolve => {
    component.setState(state, () => resolve(state))
  })
}

export function tryPromises<A, B>(runner: (a: A) => Promise<B | 404>, as: A[]): Promise<B | 404> {
  if (as.length === 0) {
    return Promise.resolve<B | 404>(404)
  }
  const [a, ...rest] = as
  const promise = runner(a)
  return promise.then(bOr404 => {
    if (bOr404 === 404) {
      return tryPromises(runner, rest)
    }
    return bOr404
  })
}
