import * as React from 'react'

import styles from './SideSubMenuInputTextarea.module.scss'

type SideSubMenuInputTextareaProps = Readonly<{
  label: string
  value: string
  disabled: boolean
  onChange: (value: string) => void
}>

export const SideSubMenuInputTextarea = (props: SideSubMenuInputTextareaProps) => {
  const onChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => props.onChange(e.target.value)

  return (
    <>
      <label className={styles.label}>{props.label}:</label>
      <textarea disabled={props.disabled} className={styles.textarea} value={props.value} onChange={onChangeTextarea} />
    </>
  )
}
