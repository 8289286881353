import * as React from 'react'

import { renderIf } from '../../../utils/rendering'

import styles from './RadioButton.module.scss'

type RadioButtonProps = Readonly<{ checked?: boolean; name?: string; label?: string; handleChange: () => void }>

export const RadioButton: React.FC<RadioButtonProps> = ({ checked, name, label, handleChange }) => (
  <label className={styles.radioButtonContainer}>
    <input className={styles.radioInput} type="radio" name={name} onChange={handleChange} checked={checked} />
    <span className={styles.radioButton} />
    {renderIf(!!label, <span className={styles.label}>{label}</span>)}
  </label>
)
