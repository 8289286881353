import { DateRangeJson, dateRangeJsonFromDateRange } from '../../Api/Companies/DateRangeJson'
import { Company } from '../../ApplicationState/Company/Company'
import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { getAuthToken } from '../../utils/auth/authClientObservable'

export async function updateCompany(company: Company) {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/companies/${company.id}/settings`

  const dateRangeJson: DateRangeJson = dateRangeJsonFromDateRange(company.allowedDateRange)
  const options = {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify({ allowedDateRange: dateRangeJson }),
  }

  return fetch(url, options).then(response => handleJSONResponse(options, response))
}
