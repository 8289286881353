import { arraysAreEqual, tripleEquals } from './equals'
import { None, none } from './strictNull'

import type { Equality } from './shared'

type MemoizeEquality<Params extends any[], Result> = Partial<
  Readonly<{
    paramsAreEqual: Equality<Params>
    resultsAreEqual: Equality<Result>
  }>
>

export function memoize<Params extends any[], Result>(
  fn: (...params: Params) => Result,
  memoizeEquality: MemoizeEquality<Params, Result> = {}
): (...params: Params) => Result {
  const paramsAreEqual: Equality<Params> = memoizeEquality.paramsAreEqual || arraysAreEqual(tripleEquals)
  const resultsAreEqual: Equality<Result> = memoizeEquality.resultsAreEqual || tripleEquals
  type LastCall = Readonly<{
    params: Params
    result: Result
  }>
  let lastCall: LastCall | None = none
  return (...params: Params): Result => {
    if (lastCall !== none && paramsAreEqual(lastCall.params, params)) {
      return lastCall.result
    }
    const result = fn(...params)
    if (lastCall !== none && resultsAreEqual(lastCall.result, result)) {
      // If the results are the same, return the *exact* same result
      // to make sure that all other memoized functions work as efficiently
      // as possible:
      lastCall = { params, result: lastCall.result }
      return lastCall.result
    }
    lastCall = { params, result }
    return result
  }
}
