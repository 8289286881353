import * as React from 'react'

import { StrictNull, None, StrictUndefined } from './strictNull'

// In some cases this function is preferable to doing a ternary operator with `null`.
export function renderIf(condition: boolean, node: React.ReactNode): React.ReactNode {
  return renderEither(condition, node, null)
}

// In some cases this function is preferable to doing a ternary operator.
export function renderEither(condition: boolean, node: React.ReactNode, alternative: React.ReactNode): React.ReactNode {
  return condition ? node : alternative
}

export function renderUndefinable<A>(a: A | undefined, fn: (a: A) => React.ReactNode): React.ReactNode {
  return StrictUndefined.fold(a, fn, null)
}

export function renderNoneable<A>(a: A | None, fn: (a: A) => React.ReactNode): React.ReactNode {
  return StrictNull.fold(a, fn, null)
}
