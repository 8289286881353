import styles from './Button.module.scss'

export enum ButtonSize {
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge,
}

export const stylesByInputSize: Record<ButtonSize, string> = {
  [ButtonSize.ExtraSmall]: styles.extraSmall,
  [ButtonSize.Small]: styles.small,
  [ButtonSize.Medium]: styles.medium,
  [ButtonSize.Large]: styles.large,
  [ButtonSize.ExtraLarge]: styles.extraLarge,
}
