import { Message } from '../../Message/Message'

import styles from './UsersManagementWarning.module.scss'

export const UsersManagementWarning = () => {
  return (
    <div className={styles.warning}>
      <Message icon="warning">The list of users is currently not available, please try again later</Message>
    </div>
  )
}
