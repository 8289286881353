export type Comparator<A> = (left: A, right: A) => number

export function reversed<A>(comparator: Comparator<A>): Comparator<A> {
  return (left: A, right: A) => {
    const compared = comparator(left, right)
    return compared === 0 ? 0 : -compared
  }
}

export function compareNumbersAsc(left: number, right: number): number {
  return left - right
}

export function compareBy<A, B>(selector: (a: A) => B, comparator: Comparator<B>): Comparator<A> {
  return (left: A, right: A) => {
    return comparator(selector(left), selector(right))
  }
}
