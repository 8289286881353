import classnames from 'classnames'

import styles from './ModalLayout.module.scss'
import { breakpointMedium } from './variables'

export type ModalLayout = Readonly<{
  stylesContent: string
  expand: boolean
  maxWidth: string
}>

const createLayout = (layout: Partial<ModalLayout>): ModalLayout => {
  // Defaults:
  const { expand = true, maxWidth = breakpointMedium } = layout
  return {
    stylesContent: classnames(styles.modalLayout, layout.stylesContent),
    expand,
    maxWidth,
  }
}

export const modalLayoutColumns: ModalLayout = createLayout({
  stylesContent: styles.layoutColumns,
})

export const modalLayoutOneColumn: ModalLayout = createLayout({
  stylesContent: styles.layoutOneColumn,
})
