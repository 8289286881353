import { Observable } from 'rxjs'

type ScrollPosition = Readonly<{
  scrollLeft: number
  scrollTop: number
}>

export function createScrollPositionObservable(elem: HTMLElement) {
  return new Observable<ScrollPosition>(subscriber => {
    const listener = () => {
      subscriber.next({ scrollTop: elem.scrollTop, scrollLeft: elem.scrollLeft })
    }
    elem.addEventListener('scroll', listener)
    listener()
    return () => {
      elem.removeEventListener('scroll', listener)
    }
  })
}
