export type PayloadOf<T> = T extends (...params: infer Data) => void ? Data : never
export type Case<O, K extends keyof O> = Readonly<{ type: K; payload: PayloadOf<O[K]> }>
export type UnionOf<O> = { [K in keyof O]: Case<O, K> }[keyof O]

type UnionOfOps<O> = Readonly<{
  match<T>(union: UnionOf<O>, cases: { [K in keyof O]: (...params: PayloadOf<O[K]>) => T }): T
  matchPartial<T>(
    union: UnionOf<O>,
    cases: Partial<{ [K in keyof O]: (...params: PayloadOf<O[K]>) => T }>,
    defaultCase: () => T
  ): T
  create<K extends keyof O>(k: K, ...params: PayloadOf<O[K]>): Case<O, K>
  withConstructors<Keys extends keyof O>(
    this: UnionOfOps<O>,
    ...keysToCreateConstructorFor: Keys[]
  ): UnionOfOps<O> & UnionOfConstructors<O, Keys>
}>

function createUnionOfOps<O>(): UnionOfOps<O> {
  return {
    match<T>(union: UnionOf<O>, cases: { [K in keyof O]: (...params: PayloadOf<O[K]>) => T }): T {
      return cases[union.type](...union.payload)
    },
    matchPartial<T>(
      union: UnionOf<O>,
      cases: Partial<{ [K in keyof O]: (...params: PayloadOf<O[K]>) => T }>,
      defaultCase: () => T
    ): T {
      const matchingCase = cases[union.type]
      if (matchingCase === undefined) {
        return defaultCase()
      }
      return matchingCase(...union.payload)
    },
    create<K extends keyof O>(k: K, ...params: PayloadOf<O[K]>): Case<O, K> {
      return { type: k, payload: params }
    },
    withConstructors<Keys extends keyof O>(
      this: UnionOfOps<O>,
      ...keysToCreateConstructorFor: Keys[]
    ): UnionOfOps<O> & UnionOfConstructors<O, Keys> {
      return {
        ...this,
        ...createUnionOfConstructors<O, Keys>(keysToCreateConstructorFor),
      }
    },
  }
}

type UnionOfConstructors<O, Keys extends keyof O> = Readonly<{
  [K in Keys]: (...params: PayloadOf<O[K]>) => Case<O, K>
}>

function createUnionOfConstructors<O, Keys extends keyof O>(keys: Keys[]): UnionOfConstructors<O, Keys> {
  const result: Partial<UnionOfConstructors<O, Keys>> = {}
  keys.forEach(key => {
    const ctor = (...params: PayloadOf<O[Keys]>): Case<O, Keys> => ({ type: key, payload: params })
    result[key] = ctor as any // Typescript 3.5 doesn't need the any
  })
  return result as UnionOfConstructors<O, Keys>
}

export function unionOfOps<O>(): UnionOfOps<O> {
  return createUnionOfOps<O>()
}
