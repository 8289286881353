import { ChangeEvent } from 'react'

import { None } from '../../../../utils/strictNull'
import { TestIds } from '../../../../utils/testIds/testIds'
import { ModalFormItem } from '../Item/ModalFormItem'

import styles from './ModalFormInput.module.scss'

type ModalFormInputProps = Readonly<{
  value: string
  label: string
  feedback: JSX.Element | string | None
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void
  testId?: TestIds
}>

export const ModalFormInput = (props: ModalFormInputProps) => {
  return (
    <ModalFormItem label={props.label} feedback={props.feedback} name={props.name}>
      {id => (
        <input
          id={id}
          className={styles.input}
          type="text"
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          name={props.name}
          data-test-id={props.testId}
        />
      )}
    </ModalFormItem>
  )
}
