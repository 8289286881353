import * as React from 'react'

import { renderNoneable } from '../../utils/rendering'
import { none, None } from '../../utils/strictNull'

import styles from './SideMenuFooter.module.scss'

interface ISideMenuFooterProps {
  userAvatarUrl: string | None
  companyName: string | None

  onLogout: () => void
}

export const SideMenuFooter = (props: ISideMenuFooterProps) => {
  const avatarStyles: React.CSSProperties =
    props.userAvatarUrl !== none ? { backgroundImage: `url(${props.userAvatarUrl})` } : {}

  return (
    <div className={styles.sideMenuFooter}>
      <div className={styles.userIcon} style={avatarStyles} />
      {renderNoneable(props.companyName, companyName => (
        <div>{companyName}</div>
      ))}
      <div onClick={props.onLogout} className={styles.logout}>
        Log out
      </div>
    </div>
  )
}
