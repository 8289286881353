import { toCompanyFromCompanyJson } from '../../Api/Companies/CompanyJson'
import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { lift } from '../../utils/arr'
import { getAuthToken } from '../../utils/auth/authClientObservable'

import type { Company } from '../../ApplicationState/Company/Company'

export async function fetchCompanies() {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/companies`
  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }

  return fetch(url, options).then((response: Response): Promise<Company[]> => {
    return handleJSONResponse(options, response).then(lift(toCompanyFromCompanyJson))
  })
}
