import { Unlocode } from '../../Domain/NauticalLocations/IPort'

export type PortView = Readonly<{
  id: Unlocode
  name: string
}>

export function toPortView(unlocode: Unlocode): PortView {
  return {
    id: unlocode,
    name: unlocode,
  }
}
