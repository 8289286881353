import { toUserFromUserJson, toUserJsonFromUser, UserJsonWithoutId } from '../../Api/Users/UserJson'
import { CompanyId } from '../../ApplicationState/Company/Company'
import { User, NewUser } from '../../ApplicationState/UsersManagement/User'
import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { getAuthToken } from '../../utils/auth/authClientObservable'

export const createUser = async (user: NewUser, companyId: CompanyId): Promise<User> => {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/${companyId}/users`

  const createUserJson: UserJsonWithoutId = toUserJsonFromUser(user)
  const options = {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify(createUserJson),
  }

  return fetch(url, options)
    .then(response => handleJSONResponse(options, response))
    .then(toUserFromUserJson)
}
