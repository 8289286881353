import * as React from 'react'

import classnames from 'classnames'

import { renderIf, renderUndefinable } from '../../utils/rendering'
import { StrictUndefined } from '../../utils/strictNull'
import { TestIds } from '../../utils/testIds/testIds'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

import styles from './Button.module.scss'
import { ButtonDisplay, stylesByButtonDisplay } from './ButtonDisplay'
import { ButtonMaterialIcon, ButtonIconAlignment } from './ButtonIcon'
import { ButtonRole, stylesByButtonRole } from './ButtonRole'
import { ButtonSize, stylesByInputSize } from './ButtonSize'
import { ButtonType } from './ButtonType'

type ButtonProps = Readonly<{
  disabled?: boolean
  display?: ButtonDisplay
  role?: ButtonRole
  size?: ButtonSize
  materialIcon?: ButtonMaterialIcon
  onClick?: () => void
  children?: React.ReactNode
  type?: ButtonType
  testId?: TestIds
}>

export const Button = (props: ButtonProps) => {
  const buttonDisplay = StrictUndefined.orElse(props.display, ButtonDisplay.InlineBlock)
  const buttonDisplayStyle = stylesByButtonDisplay[buttonDisplay]

  const buttonRole = StrictUndefined.orElse(props.role, ButtonRole.Primary)
  const buttonRoleStyle = stylesByButtonRole[buttonRole]

  const buttonSize = StrictUndefined.orElse(props.size, ButtonSize.Medium)
  const buttonSizeStyle = stylesByInputSize[buttonSize]

  const hasLeftIcon = StrictUndefined.fold(
    props.materialIcon,
    icon => icon.alignment === ButtonIconAlignment.Left,
    false
  )
  const hasRightIcon = StrictUndefined.fold(
    props.materialIcon,
    icon => icon.alignment === ButtonIconAlignment.Right,
    false
  )
  const hasIcon = hasLeftIcon || hasRightIcon

  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={classnames(styles.button, buttonDisplayStyle, buttonRoleStyle, buttonSizeStyle, {
        [styles.withIcon]: hasIcon,
        [styles.withLeftIcon]: hasLeftIcon,
        [styles.withRightIcon]: hasRightIcon,
      })}
      disabled={props.disabled}
      data-test-id={props.testId}
    >
      {renderUndefinable(props.materialIcon, icon =>
        renderIf(hasLeftIcon, <MaterialIcon type={icon.type} className={styles.icon} />)
      )}
      {props.children}
      {renderUndefinable(props.materialIcon, icon =>
        renderIf(hasRightIcon, <MaterialIcon type={icon.type} className={styles.icon} />)
      )}
    </button>
  )
}
