import { PRODUCT_NAME } from '../../../config'

export type ConfirmView = Readonly<{
  title: string
  subtitle?: string
  text: string
  confirmationButtonLabel: string
}>

export const confirmDeleteView: ConfirmView = {
  title: 'Are you sure you want to delete user account?',
  subtitle: `${PRODUCT_NAME} admin module`,
  text: [
    'You are about to delete an account and all data associated with it (passwords, filters, preferences, etc.) This action can not be reverted.',
    'If this is not what you intend, please consider deactivating the user account. This will prevent the user from logging in, but all data associated with the account is preserved and will be remembered for when the account is activated again.',
    'Are you sure you want to proceed?',
  ].join('\n'),
  confirmationButtonLabel: 'Yes, delete account',
}

export const confirmDeactivateView: ConfirmView = {
  title: 'Are you sure you want to deactivate user account?',
  subtitle: `${PRODUCT_NAME} admin module`,
  text: [
    'You are about to deactivate an account. The user will no longer be able to sign in but all data associated with the account (passwords, filters, preferences, etc.) will be preserved for future use.',
    'If you want to permanently remove the account, please consider deleting the account.',
    'Are you sure you want to proceed?',
  ].join('\n'),
  confirmationButtonLabel: 'Yes, deactivate account',
}

export const confirmActivateView: ConfirmView = {
  title: 'Are you sure you want to activate user account?',
  subtitle: `${PRODUCT_NAME} admin module`,
  text: [
    `You are about to re-activate an account. You are restoring the user's access to ${PRODUCT_NAME} and all associated privileges.`,
    'Are you sure you want to proceed?',
  ].join('\n'),
  confirmationButtonLabel: 'Yes, activate account',
}

export enum WhatToConfirm {
  Delete,
  DeactivateAccount,
  ActivateAccount,
}
