import { mapStackTrace } from 'sourcemapped-stacktrace/dist/sourcemapped-stacktrace'

import { LOG_FRONTEND_ERRORS, SOURCE_VERSION } from '../config'
import { APP_ERRORS } from '../constants/errors'
import { logError } from '../shared/utils/logError'

let ACTION_HISTORY: string[] = []

const EXCLUDED_ERRORS_FROM_LOGGING = [APP_ERRORS.LOGIN_EXPIRED]

function stacktraceAsArray(error: Error): Promise<string[]> {
  const { stack } = error

  if (!stack) {
    return Promise.resolve([])
  }

  return new Promise<string[]>(resolve => {
    mapStackTrace(stack, resolve)
  })
}

declare global {
  interface WindowEventMap {
    unhandledrejection: PromiseRejectionEvent
  }
}

interface IAppErrorMessage {
  reference: string
  error: any
}

// `errorMsg` could really be any
// we stringify `errorMsg` to get nice error messages
export const onGlobalError = (
  errorMsg: IAppErrorMessage,
  filename?: string,
  lineno?: number,
  colno?: number,
  error?: Error
) => {
  const stackTracePromise = stacktraceAsArray(error || new Error())
  stackTracePromise.then(stackTrace => {
    const errorMessageWithLocation = `${JSON.stringify(
      errorMsg
    )} (in file ${filename} at line ${lineno} and column ${colno})`
    errorLogger(errorMessageWithLocation, stackTrace, window.navigator.userAgent, ACTION_HISTORY)
  })

  // reset action history on error to build up a clean history
  ACTION_HISTORY = []

  // return false to let the browser handle the error as usual
  return false
}

const errorLogger = (message: string, stacktrace: string[], userAgent: string, actionHistory: string[]) => {
  // check if the error is not in our blacklist
  const errorNotExcludedFromLogging =
    EXCLUDED_ERRORS_FROM_LOGGING.find(excludedError => message.indexOf(excludedError) > -1) === undefined
  const url = window.location.href

  if (errorNotExcludedFromLogging) {
    if (LOG_FRONTEND_ERRORS) {
      logError({ message, sourceVersion: SOURCE_VERSION, stacktrace, userAgent, actionHistory, url })
    } else {
      // eslint-disable-next-line no-console
      console.error({ message, sourceVersion: SOURCE_VERSION, stacktrace, userAgent, actionHistory, url })
    }
  }
}
