import * as React from 'react'

import { filterMap } from '../../utils/arr'
import { tripleEquals } from '../../utils/equals'
import { noOp } from '../../utils/functions'
import { none, StrictUndefined } from '../../utils/strictNull'
import { RadioButton } from '../Buttons/RadioButton/RadioButton'
import { GenericDropdownTheme, GenericDropdownWithClickOutside } from '../GenericDropdown/GenericDropdown'
import { GenericDropdownOptionTheme, GenericDropdownOption } from '../GenericDropdown/GenericDropdownOption'

import type { Equality } from '../../utils/shared'

export type SelectDropdownTheme = GenericDropdownTheme & GenericDropdownOptionTheme

type SelectDropdownConfig<Id, Option> = Readonly<{
  getId: (option: Option) => Id
  idsAreEqual: Equality<Id>
  getOptionName: (option: Option) => string
  getOptionDescription: (option: Option) => React.ReactNode
  getOptionKey: (option: Option, index: number) => number | string
}>

export type SelectDropdownProps<Id, Option> = Readonly<{
  id?: string
  selectedId?: Id
  options: Option[]
  onChange: (selectedId: Id) => void
  theme?: SelectDropdownTheme
  showCheckbox?: boolean
  closeDropdownOnSelect?: boolean
  filterFn?: (option: Option, query: string) => boolean
}>

export function SelectDropdown<Id, Option>({
  showCheckbox = true,
  ...props
}: SelectDropdownProps<Id, Option> & SelectDropdownConfig<Id, Option>) {
  const [query, setQuery] = React.useState('')
  function getSelectionOptionDescription(): string {
    const { selectedId } = props

    if (selectedId !== undefined) {
      const possibleOption = props.options.find(option => props.idsAreEqual(props.getId(option), selectedId))

      return StrictUndefined.fold(possibleOption, props.getOptionName, '')
    }
    return ''
  }

  return (
    <GenericDropdownWithClickOutside
      id={props.id}
      theme={props.theme}
      head={getSelectionOptionDescription()}
      searchable={StrictUndefined.isNotUndefined(props.filterFn) && { query, setQuery }}
      body={({ closeDropdown }) =>
        filterMap(props.options, (option, index) => {
          if (props.filterFn && !props.filterFn(option, query)) {
            return none
          }
          const id = props.getId(option)
          const isSelected = StrictUndefined.isNotUndefined(props.selectedId) && props.idsAreEqual(id, props.selectedId)
          return (
            <GenericDropdownOption
              icon={showCheckbox && <RadioButton checked={isSelected} handleChange={noOp} />}
              theme={props.theme}
              key={props.getOptionKey(option, index)}
              onClick={() => {
                if (!isSelected) {
                  props.onChange(id)
                }
                if (props.closeDropdownOnSelect) {
                  closeDropdown()
                }
              }}
            >
              {props.getOptionDescription(option)}
            </GenericDropdownOption>
          )
        })
      }
    />
  )
}

export type StandardizedOption<Id> = Readonly<{ id: Id; name: string }>

export function StandardizedSelectDropdown<Id extends string | number, Option extends StandardizedOption<Id>>(
  props: SelectDropdownProps<Id, Option>
) {
  return (
    <SelectDropdown
      {...props}
      getId={o => o.id}
      idsAreEqual={tripleEquals}
      getOptionName={o => o.name}
      getOptionDescription={o => o.name}
      getOptionKey={o => o.id}
    />
  )
}
