import * as React from 'react'

import styles from './SideSubMenuInputText.module.scss'

type SideSubMenuInputTextProps = Readonly<{
  label: string
  value: string
  disabled: boolean
  onChange: (value: string) => void
}>

export const SideSubMenuInputText = (props: SideSubMenuInputTextProps) => {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)

  return (
    <>
      <label className={styles.label}>{props.label}:</label>
      <input
        className={styles.input}
        disabled={props.disabled}
        type="text"
        value={props.value}
        onChange={onChangeInput}
      />
    </>
  )
}
