import { PropsWithChildren } from 'react'

import classnames from 'classnames'

import { noOp } from '../../utils/functions'
import { renderIf } from '../../utils/rendering'

import styles from './Checkbox.module.scss'

interface ICheckboxProps {
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
  theme?: CheckboxTheme
}

export type CheckboxTheme = Partial<{
  checkbox: string
  checkboxLabel: string
}>

const defaultCheckboxTheme: CheckboxTheme = {
  checkboxLabel: styles.defaultCheckboxLabel,
}

const CheckMarkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="svg14" version="1.1" viewBox="0 0 18 18" height="18px" width="18px">
    <title id="title2">Element/Checkbox/Selected</title>
    <desc id="desc4">Created with Sketch.</desc>
    <polygon id="Path" points="7,11.307692 14.6,4 16,5.3461539 7,14 2,9.1923077 3.4,7.8461539 " fill="#fff" />
  </svg>
)

type CheckmarkProps = PropsWithChildren<ICheckboxProps>
export const Checkmark = ({ disabled, checked = false, theme = {}, children }: CheckmarkProps) => (
  <label
    className={classnames(styles.checkbox, theme.checkbox || defaultCheckboxTheme.checkbox, {
      [styles.disabled]: disabled,
    })}
  >
    <span className={classnames(styles.checkboxButton, { [styles.checkboxChecked]: checked })}>
      {renderIf(checked, <CheckMarkIcon />)}
    </span>
    {children}
  </label>
)

export const Checkbox = ({ label, checked = false, disabled = false, onChange = noOp, theme = {} }: ICheckboxProps) => (
  <Checkmark disabled={disabled} checked={checked} theme={theme}>
    <input type="checkbox" checked={checked} onChange={disabled ? noOp : () => onChange(!checked)} />
    {renderIf(
      label !== undefined,
      <div className={classnames(styles.checkboxLabel, theme.checkboxLabel)}>{label}</div>
    )}
  </Checkmark>
)
