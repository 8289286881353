import { Formik } from 'formik'

import { Company } from '../../../ApplicationState/Company/Company'
import { PRODUCT_NAME } from '../../../config'
import { IDateRange } from '../../../shared/interfaces/filters/IFilters'
import { IDateRangeOptions } from '../../../store/filters/filters.interfaces'
import { Path } from '../../../utils/Path'
import { renderNoneable } from '../../../utils/rendering'
import { None } from '../../../utils/strictNull'
import { Button } from '../../Button/Button'
import { BUTTON_TYPE_SUBMIT } from '../../Button/ButtonType'
import { DateRangeOption } from '../../FilterOptions/DateRangeOption'
import { FormField } from '../../FormField/FormField'
import { ModalFooter } from '../../Modal/Footer/ModalFooter'
import { ModalHeader } from '../../Modal/Header/ModalHeader'
import { Modal } from '../../Modal/Modal'
import { ModalContent } from '../../Modal/ModalContent'
import { ModalSubtitle } from '../../Modal/Subtitle/ModalSubtitle'
import { ModalTitle } from '../../Modal/Title/ModalTitle'

export type CompanyManagementModalProps = Readonly<{
  company: Company
  dateRangeOptions: IDateRangeOptions
  errorMessage: string | None
  onClose: () => void
  onSave: (company: Company) => void
}>

type CompanyForm = Readonly<{
  dateRange: IDateRange
}>

const formPath = Path.obj<CompanyForm>()

export const CompanyManagementModal = (props: CompanyManagementModalProps) => {
  const companyForm: CompanyForm = {
    dateRange: props.company.allowedDateRange,
  }

  return (
    <Modal onClose={props.onClose}>
      <Formik<CompanyForm>
        initialValues={companyForm}
        onSubmit={formValues => props.onSave({ ...props.company, allowedDateRange: formValues.dateRange })}
      >
        {({ handleSubmit, touched }) => {
          return (
            <>
              <ModalHeader>
                <ModalTitle>{props.company.name}</ModalTitle>
                <ModalSubtitle>{PRODUCT_NAME} admin module</ModalSubtitle>
              </ModalHeader>

              <ModalContent>
                <FormField name={formPath.key('dateRange')}>
                  {({ form }) => (
                    <DateRangeOption
                      dateRange={form.values.dateRange}
                      label="Maximum time range:"
                      dateRangeOptions={props.dateRangeOptions}
                      onChange={newDateRange => form.setFieldValue(formPath.key('dateRange').totalPath, newDateRange)}
                    />
                  )}
                </FormField>
              </ModalContent>

              <ModalFooter>
                <Button type={BUTTON_TYPE_SUBMIT} disabled={!touched} onClick={handleSubmit}>
                  Save
                </Button>
                {renderNoneable(props.errorMessage, errorMessage => (
                  <div>{errorMessage}</div>
                ))}
              </ModalFooter>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}
