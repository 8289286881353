import { useState, useEffect } from 'react'

import { Company, CompanyId } from '../../ApplicationState/Company/Company'
import { UserId, User } from '../../ApplicationState/UsersManagement/User'
import { FullLoadingLoader } from '../../components/Loader/FullLoadingLoader/FullLoadingLoader'
import { UsersManagementOverview as PresentationComponent } from '../../components/UsersManagement/Overview/UsersManagementOverview'
import { UsersManagementWarning } from '../../components/UsersManagement/Warning/UsersManagementWarning'
import { IUser } from '../../shared/interfaces/settings/Settings'
import { fetchConfig } from '../../shared/rest/filters'
import { hasPermissions } from '../../store/authorizations/permissions.selectors'
import { notFoundStatusCode, NotFoundStatusCode } from '../../utils/NotFound'
import { Strings } from '../../utils/str'
import { none, None } from '../../utils/strictNull'
import { throwUnreachable } from '../../utils/throwUnreachable'
import { GroupView, toGroupView } from '../../View/UsersManagement/GroupView'
import { LocaleView, toLocaleView } from '../../View/UsersManagement/LocaleView'
import {
  ModalNewUser,
  ModalEditUser,
  MODAL_TYPE_NEW_USER,
  MODAL_TYPE_EDIT_USER,
} from '../../View/UsersManagement/Modal'
import { PortView, toPortView } from '../../View/UsersManagement/PortView'
import { RoleView, toRoleView } from '../../View/UsersManagement/RoleView'
import { UserView, EditUserView, getNewUserView, toUserViewFromUser } from '../../View/UsersManagement/UsersView'
import { fetchCompanies } from '../CompanyManagement/fetchCompanies'

import { createUser } from './createUser'
import { deleteUser } from './deleteUser'
import { fetchAssignableRolesAndGroups } from './fetchAssignableRolesAndGroups'
import { fetchUsers } from './fetchUsers'
import { updateUser } from './updateUser'

export const UsersManagementOverview = ({ adminUser }: { adminUser: IUser }) => {
  const [users, setUsers] = useState<UserView[] | None | NotFoundStatusCode>(none)
  const [companies, setCompanies] = useState<Company[] | None | NotFoundStatusCode>(none)
  const [selectedCompany, setSelectedCompany] = useState<Company>(adminUser.company)
  const [roles, setRoles] = useState<RoleView[]>([])
  const [groups, setGroups] = useState<GroupView[]>([])
  const [ports, setPorts] = useState<PortView[]>([])
  const [locales, setLocales] = useState<LocaleView[]>([])
  const [userModal, setModal] = useState<ModalNewUser | ModalEditUser | None>(none)
  const isUserSuperAdmin = hasPermissions(
    ['readanycompany:users', 'read:users', 'read:companies'],
    adminUser.permissions
  )

  useEffect(() => {
    const defaultCompany = adminUser.company
    setSelectedCompany(defaultCompany)

    if (isUserSuperAdmin) {
      const fetchCompanyList = async () => {
        try {
          const companyList: Company[] = await fetchCompanies()
          return companyList.sort((a, b) => Strings.localeCompare(a.name, b.name))
        } catch {
          // When unable to list all companies, only adding the user's company to dropdown
          return [defaultCompany]
        }
      }
      fetchCompanyList().then(setCompanies)
    } else {
      // When the user has not the UserSuperAdmin role, he can only manage his own company. Only adding his own company
      // to the dropdown with companies
      setCompanies([defaultCompany])
    }
  }, [isUserSuperAdmin, adminUser])

  useEffect(() => {
    const fetchUserList = async (companyId: CompanyId) => {
      try {
        const supportedLocales = await fetchConfig().then(c => c.supportedLocales)
        const usersList: User[] = await fetchUsers(companyId)
        const assignableRolesAndGroups = await fetchAssignableRolesAndGroups()

        setUsers(usersList.map(toUserViewFromUser))

        const adminGroups: GroupView[] = assignableRolesAndGroups.groups.map(toGroupView)
        setGroups(adminGroups)

        const adminRoles: RoleView[] = assignableRolesAndGroups.roles.map(toRoleView)
        setRoles(adminRoles)

        // An user can only have as ports the ports of this user admin.
        const adminPorts: PortView[] = adminUser.ports.map(toPortView)
        setPorts(adminPorts)

        const localeViews: LocaleView[] = supportedLocales.map(toLocaleView)
        setLocales(localeViews)
      } catch {
        setUsers(notFoundStatusCode)
      }
    }
    fetchUserList(selectedCompany.id)
  }, [selectedCompany, adminUser])

  if (users === none || companies === none) {
    return <FullLoadingLoader />
  }

  if (users === notFoundStatusCode || companies === notFoundStatusCode) {
    return <UsersManagementWarning />
  }

  const onChangeCompany = (companyId: CompanyId) => {
    const newSelectedCompany = companies.find(comp => comp.id === companyId)

    if (newSelectedCompany) {
      setSelectedCompany(newSelectedCompany)
    }
  }

  const onOpenNewUserModal = () => {
    setModal({
      type: MODAL_TYPE_NEW_USER,
      user: getNewUserView(),
    })
  }

  const onOpenEditUserModal = (user: EditUserView) => {
    setModal({
      type: MODAL_TYPE_EDIT_USER,
      user,
    })
  }

  const closeModal = () => {
    setModal(none)
  }

  const saveModal = async (modal: ModalNewUser | ModalEditUser) => {
    switch (modal.type) {
      case MODAL_TYPE_NEW_USER: {
        const newUserFromUserView: User<None> = modal.user
        const newUser: User = await createUser(newUserFromUserView, selectedCompany.id)
        // shows the new user at the beginning of the table
        setUsers([newUser, ...users])
        break
      }
      case MODAL_TYPE_EDIT_USER: {
        const editedUserFromUserView: User = modal.user
        const editedUser: User = await updateUser(editedUserFromUserView, selectedCompany.id)
        setUsers(users.map(u => (u.id === editedUser.id ? editedUser : u)))
        break
      }
      default:
        throwUnreachable(modal)
    }
    closeModal()
  }

  const onDeleteUser = async (userId: UserId) => {
    const userDeleted: boolean = await deleteUser(userId, selectedCompany.id)
    if (userDeleted) {
      setUsers(users.filter(u => u.id !== userId))
    }
    closeModal()
  }

  return (
    <PresentationComponent
      companies={companies}
      selectedCompany={selectedCompany}
      users={users}
      roles={roles}
      groups={groups}
      ports={ports}
      locales={locales}
      modal={userModal}
      onChangeCompany={onChangeCompany}
      onOpenNewUserModal={onOpenNewUserModal}
      onOpenEditUserModal={onOpenEditUserModal}
      onSaveModal={saveModal}
      onCloseModal={closeModal}
      onDeleteUser={onDeleteUser}
    />
  )
}
