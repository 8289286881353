import * as React from 'react'

import { filterMap } from '../../utils/arr'
import { tripleEquals } from '../../utils/equals'
import { none, StrictUndefined } from '../../utils/strictNull'
import { Checkmark } from '../Checkbox/Checkbox'
import { GenericDropdownWithClickOutside, GenericDropdownTheme } from '../GenericDropdown/GenericDropdown'
import { GenericDropdownOption, GenericDropdownOptionTheme } from '../GenericDropdown/GenericDropdownOption'

import type { Equality } from '../../utils/shared'

export type MultiSelectDropdownTheme = GenericDropdownTheme & GenericDropdownOptionTheme

type MultiSelectDropdownConfig<Id, Option> = Readonly<{
  getId: (option: Option) => Id
  idsAreEqual: Equality<Id>
  getOptionDescription: (option: Option) => React.ReactNode
  getOptionKey: (option: Option, index: number) => number | string
}>

export type MultiSelectDropdownProps<Id, Option> = Readonly<{
  id?: string
  selectedIds: Id[]
  options: Option[]
  onChange: (selectedIds: Id[]) => void
  theme?: MultiSelectDropdownTheme
  filterFn?: (option: Option, query: string) => boolean
}>

export function MultiSelectDropdown<Id, Option>(
  props: MultiSelectDropdownProps<Id, Option> & MultiSelectDropdownConfig<Id, Option>
) {
  const [query, setQuery] = React.useState('')

  return (
    <GenericDropdownWithClickOutside
      id={props.id}
      theme={props.theme}
      head={`${props.selectedIds.length} selected`}
      searchable={StrictUndefined.isNotUndefined(props.filterFn) && { query, setQuery }}
      body={() =>
        filterMap(props.options, (option, index) => {
          if (props.filterFn && !props.filterFn(option, query)) {
            return none
          }
          const id = props.getId(option)
          const isSelected = props.selectedIds.find(selectedId => props.idsAreEqual(id, selectedId)) !== undefined
          return (
            <GenericDropdownOption
              icon={<Checkmark checked={isSelected} />}
              theme={props.theme}
              key={props.getOptionKey(option, index)}
              onClick={() => {
                if (isSelected) {
                  props.onChange(props.selectedIds.filter(selectedId => !props.idsAreEqual(selectedId, id)))
                } else {
                  props.onChange([...props.selectedIds, id])
                }
              }}
            >
              {props.getOptionDescription(option)}
            </GenericDropdownOption>
          )
        })
      }
    />
  )
}

export type StandardizedOption<Id> = Readonly<{ id: Id; name: string }>
export function StandardizedMultiSelectDropdown<Id extends string | number, Option extends StandardizedOption<Id>>(
  props: MultiSelectDropdownProps<Id, Option>
) {
  return (
    <MultiSelectDropdown
      {...props}
      getId={o => o.id}
      idsAreEqual={tripleEquals}
      getOptionDescription={o => o.name}
      getOptionKey={o => o.id}
    />
  )
}
