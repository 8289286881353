import styles from './Button.module.scss'

export enum ButtonDisplay {
  InlineBlock,
  Block,
}

export const stylesByButtonDisplay: Record<ButtonDisplay, string> = {
  [ButtonDisplay.InlineBlock]: styles.inlineBlock,
  [ButtonDisplay.Block]: styles.block,
}
