import { stableSort } from '../../utils/arr'
import { Comparator, compareBy } from '../../utils/ordering'
import { SortDirection, getComparatorForSortDirection } from '../Sorting/SortDirection'

import { Column, createColumn } from './Column'

export type SortableColumn<Id extends string | number, Value, Node, AdditionalProps> = Column<
  Id,
  Value,
  Node,
  AdditionalProps
> &
  Readonly<{
    sortValues(values: Value[], sortDirection: SortDirection): Value[]
  }>

export function createSortableColumn<Id extends string | number, Outer, Inner, Node, AdditionalProps>(
  id: Id,
  header: string,
  accessor: (outer: Outer) => Inner,
  formatter: (inner: Inner, outer: Outer, additionalProps: AdditionalProps) => Node,
  innerOrderingAscending: Comparator<Inner>
): SortableColumn<Id, Outer, Node, AdditionalProps> {
  return {
    ...createColumn(id, header, accessor, formatter),
    sortValues(values: Outer[], sortDirection: SortDirection): Outer[] {
      const innerOrdering = getComparatorForSortDirection(innerOrderingAscending, sortDirection)
      return stableSort(values, compareBy(accessor, innerOrdering))
    },
  }
}
