import * as React from 'react'

import classnames from 'classnames'

import { renderIf } from '../../utils/rendering'
import { StrictUndefined } from '../../utils/strictNull'

import styles, { Styles } from './GenericDropdownOption.module.scss'

export type GenericDropdownOptionTheme = Partial<Styles>

export type GenericDropdownOptionProps = Readonly<{
  selected?: boolean
  icon?: React.ReactNode
  children?: React.ReactNode
  theme?: GenericDropdownOptionTheme
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}>

export const GenericDropdownOption = (props: GenericDropdownOptionProps) => {
  const selected = StrictUndefined.orElse(props.selected, false)
  const theme = props.theme || {}

  return (
    <button
      type="button"
      className={classnames(styles.option, theme.option, { [styles.selected]: selected })}
      onClick={props.onClick}
    >
      {renderIf(props.icon !== undefined, <span>{props.icon}</span>)}
      <span>{props.children}</span>
    </button>
  )
}
