export const arraysAreEqual =
  <A>(elementsAreEqual: (left: A, right: A) => boolean) =>
  (left: A[], right: A[]): boolean => {
    if (left.length !== right.length) {
      return false
    }

    return left.every((leftValue, index) => {
      const rightValue = right[index]
      return elementsAreEqual(leftValue, rightValue)
    })
  }

export function tripleEquals<A>(left: A, right: A): boolean {
  return left === right
}
