import * as React from 'react'

import classnames from 'classnames'

import styles from './ModalFooter.module.scss'

type ModalFooterProps = Readonly<{
  className?: string
  children?: React.ReactNode
}>

export const ModalFooter = (props: ModalFooterProps) => (
  <div className={classnames(styles.footer, props.className)}>{props.children}</div>
)
