import { ErrorMessage } from 'formik'

import { Path } from '../../../../utils/Path'
import { TestIds } from '../../../../utils/testIds/testIds'
import { FormField } from '../../../FormField/FormField'
import { StandardizedOption, StandardizedMultiSelectDropdown } from '../../../MultiSelectDropdown/MultiSelectDropdown'
import { ModalFormItem } from '../Item/ModalFormItem'

type ModalMultiSelectFieldProps<P, Id extends string | number, Option extends StandardizedOption<Id>> = Readonly<{
  label: string
  name: Path<P, Id[]>
  options: Option[]
  testId?: TestIds
}>

export function ModalMultiSelectField<P, Id extends string | number, Option extends StandardizedOption<Id>>(
  props: ModalMultiSelectFieldProps<P, Id, Option>
) {
  return (
    <FormField name={props.name}>
      {({ field, form }) => {
        return (
          <ModalFormItem
            label={props.label}
            testId={props.testId}
            feedback={<ErrorMessage name={field.name} />}
            name={field.name}
          >
            {id => (
              <StandardizedMultiSelectDropdown<Id, Option>
                id={id}
                selectedIds={field.value}
                onChange={selectedGroupIds => {
                  form.setFieldValue(field.name, selectedGroupIds, true)
                  form.setFieldTouched(field.name, true, false)
                }}
                options={props.options}
                filterFn={(option, query) => option.name.toLowerCase().includes(query.toLowerCase())}
              />
            )}
          </ModalFormItem>
        )
      }}
    </FormField>
  )
}
