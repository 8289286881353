import ResizeObserver from 'resize-observer-polyfill'
import { Observable } from 'rxjs'

type Resized = Readonly<{
  clientRect: DOMRect
  target: Element
}>

export function createResizeObservable(elem: HTMLElement) {
  elem.getBoundingClientRect()
  return new Observable<Resized>(subscriber => {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        subscriber.next({ clientRect: entry.contentRect, target: entry.target })
      })
    })
    subscriber.next({ clientRect: elem.getBoundingClientRect(), target: elem })
    resizeObserver.observe(elem)
    return () => {
      resizeObserver.unobserve(elem)
    }
  })
}
