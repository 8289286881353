import * as React from 'react'

import classnames from 'classnames'

type ModalContentProps = Readonly<{
  className?: string
  children?: React.ReactNode
}>

// ModalContent is just a wrapper for semantics reasons
export const ModalContent = (props: ModalContentProps) => (
  <div className={classnames(props.className)}>{props.children}</div>
)
