import * as React from 'react'

import classnames from 'classnames'

import { identity } from '../../../utils/functions'
import { None, none } from '../../../utils/strictNull'
import { createColumn, createEmptyColumn } from '../../../View/Tables/Column'
import { createFilterableColumn } from '../../../View/Tables/FilterableColumn'
import { UserView } from '../../../View/UsersManagement/UsersView'
import { ChevronRightIcon } from '../../Icon/ChevronRightIcon'
import { InlineLoader, lightBackgroundInlineLoader } from '../../Loader/InlineLoader'
import { TableConfigColumns, createTable, TableTheme } from '../../Table/Table'
import { withTicker } from '../../Table/withTicker'

import styles from './UsersManagementTable.module.scss'
import { rowWidth, editIconColor, editIconSize } from './variables'

export enum ColumnType {
  Edit = 'Edit',
  Status = 'Status',
  Gutter = 'Gutter',
}

export enum FilterableColumnType {
  Name = 'Name',
  Surname = 'Surname',
  Email = 'Email',
}

const renderEditCell = () => (
  <div className={styles.button}>
    <ChevronRightIcon width={editIconSize} height={editIconSize} color={editIconColor} />
  </div>
)

const renderStatusCell = (blocked: boolean) => (
  <div className={styles.status}>
    <span
      className={classnames(styles.icon, {
        [styles.blocked]: blocked,
        [styles.active]: !blocked,
      })}
    />
  </div>
)

type AdditionalProps = Readonly<{
  onEditUser: (user: UserView) => void
}>

const columns: TableConfigColumns<ColumnType, never, FilterableColumnType, UserView, React.ReactNode, AdditionalProps> =
  {
    [FilterableColumnType.Name]: createFilterableColumn(
      FilterableColumnType.Name,
      'First Name',
      (user: UserView) => user.firstName,
      identity,
      identity
    ),
    [FilterableColumnType.Surname]: createFilterableColumn(
      FilterableColumnType.Surname,
      'Last Name',
      (user: UserView) => user.lastName,
      identity,
      identity
    ),
    [FilterableColumnType.Email]: createFilterableColumn(
      FilterableColumnType.Email,
      'Email',
      (user: UserView) => user.email,
      withTicker(identity),
      identity
    ),
    [ColumnType.Gutter]: createEmptyColumn(ColumnType.Gutter),
    [ColumnType.Status]: createColumn(ColumnType.Status, 'Status', (user: UserView) => user.blocked, renderStatusCell),
    [ColumnType.Edit]: createColumn(ColumnType.Edit, '', identity, renderEditCell),
  }

const cellStyles: Record<ColumnType | FilterableColumnType, string> = {
  [FilterableColumnType.Name]: styles.name,
  [FilterableColumnType.Surname]: styles.surname,
  [FilterableColumnType.Email]: styles.email,
  [ColumnType.Gutter]: styles.gutter,
  [ColumnType.Status]: styles.status,
  [ColumnType.Edit]: styles.edit,
}

const Table = createTable<ColumnType, never, FilterableColumnType, UserView, React.ReactNode, AdditionalProps>({
  columns,
  allColumnTypes: [
    FilterableColumnType.Surname,
    FilterableColumnType.Name,
    FilterableColumnType.Email,
    ColumnType.Gutter,
    ColumnType.Status,
    ColumnType.Edit,
  ],
  sortableColumnTypes: [],
  filterableColumnTypes: [FilterableColumnType.Surname, FilterableColumnType.Name, FilterableColumnType.Email],
  getKey: user => `${user.id}`,
  styles: cellStyles,
  rowWidth,
  onClick: (user, index, additionalProps) => {
    additionalProps.onEditUser(user)
  },
})

const tableTheme: TableTheme = {
  row: styles.row,
  body: styles.body,
  header: styles.header,
  cell: styles.cell,
}

type UsersManagementListProps = Readonly<{
  users: UserView[] | None
  onEditUser: (user: UserView) => void
}>

export const UsersManagementTable = React.memo((props: UsersManagementListProps) => {
  if (props.users === none) {
    return (
      <InlineLoader
        theme={{ ...lightBackgroundInlineLoader, loaderInline: lightBackgroundInlineLoader.spinnerWrapper }}
      />
    )
  }

  return (
    <div className={styles.usersManagementTable}>
      <Table theme={tableTheme} values={props.users} onEditUser={props.onEditUser} />
    </div>
  )
})
