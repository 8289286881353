import { useEffect, useMemo, useState } from 'react'

import ReactDOM from 'react-dom'
import jwtDecode from 'jwt-decode'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { auth0Options } from './utils/auth/auth0config'
import { RootComponent } from './components/Root/Root'
import { PrimaryMenu } from './components/Header/PrimaryMenu/PrimaryMenu'
import { Header } from './modules/Header/Header.container'
import { UsersManagementOverview } from './modules/UsersManagement/UsersManagementOverview'
import { COMPANY_MANAGEMENT_PAGE, PAGES, USERS_MANAGEMENT_PAGE } from './constants/pages'
import { IsAuthenticated } from './modules/AuthGate/IsAuthenticated'
import { IUser } from './shared/interfaces/settings/Settings'
import { CompanyManagementOverview } from './modules/CompanyManagement/CompanyManagementOverview'
import { Modal } from './components/Modal/Modal'
import { ModalHeader } from './components/Modal/Header/ModalHeader'
import { ModalTitle } from './components/Modal/Title/ModalTitle'
import { ModalSubtitle } from './components/Modal/Subtitle/ModalSubtitle'
import { ModalContent } from './components/Modal/ModalContent'
import { ModalFooter } from './components/Modal/Footer/ModalFooter'

const COMPANY_MANAGEMENT_ACCESS = 'read:companies'
const USER_MANGEMENT_ACCESS = 'read:users'
const COMPANY_USER_MANAGEMENT_ACCESS = 'readanycompany:users'
const AuthorizationKey = 'https://pronto.portcalloptimization.nl/authorization'

// Hardcoded for now as all and only USHOU will have access to PilotTracker
const isPilotTrackerUser = (user: IUser) => user.ports.some(p => ['USHOU', 'USCRP'].includes(p))

function LoginWithRedirect() {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect({ max_age: 0 })
  }, [loginWithRedirect])

  return null
}

function RedirectToDefaultPage({
  access: { userManagement, companyManagement },
}: {
  access: { userManagement: boolean; companyManagement: boolean }
}) {
  if (userManagement) {
    return <Redirect to={PAGES.USERS_MANAGEMENT} />
  }

  if (companyManagement) {
    return <Redirect to={PAGES.COMPANY_MANAGEMENT} />
  }

  return (
    <Modal onClose={() => {}}>
      <ModalHeader>
        <ModalTitle>Access denied</ModalTitle>
        <ModalSubtitle>We're sorry!</ModalSubtitle>
      </ModalHeader>
      <ModalContent>
        <p>At this moment you dont have access to the available functionality</p>
        <p>Please contact support if this is unexpected</p>
      </ModalContent>
      <ModalFooter />
    </Modal>
  )
}

function App({ user, token }: { user: IUser; token: string }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isAuthenticated, logout } = useAuth0()
  const { permissions } = user

  const { hasCompanyManagementAccess, hasUserManagementAccess } = useMemo(
    () => ({
      hasCompanyManagementAccess: permissions.includes(COMPANY_MANAGEMENT_ACCESS),
      hasUserManagementAccess:
        permissions.includes(USER_MANGEMENT_ACCESS) || permissions.includes(COMPANY_USER_MANAGEMENT_ACCESS),
    }),
    [permissions]
  )

  const roles = useMemo(() => {
    const roles = { hasShipTracker: false }

    try {
      const jwt: Partial<{ [AuthorizationKey]: { roles: string[] } }> = jwtDecode(token)

      roles.hasShipTracker = jwt[AuthorizationKey]?.roles.includes('ShipTracker') ?? false
    } catch {}

    return roles
  }, [token])

  return isAuthenticated ? (
    <>
      <PrimaryMenu toggleHamburger={() => setIsMenuOpen(!isMenuOpen)} />
      {isMenuOpen && (
        <Header
          user={user}
          onClose={() => setIsMenuOpen(false)}
          onLogout={() => logout({ returnTo: auth0Options.redirect_uri })}
          shouldShowLinkToCompanyManagement={hasCompanyManagementAccess}
          shouldShowLinkToUsersManagement={hasUserManagementAccess}
          shouldShowLinkToPilotTracker={isPilotTrackerUser(user)}
          shouldShowLinkToShipTracker={isPilotTrackerUser(user) && roles.hasShipTracker}
        />
      )}
      <Switch>
        <Route exact path={PAGES[USERS_MANAGEMENT_PAGE]}>
          <UsersManagementOverview adminUser={user} />
        </Route>
        <Route exact path={PAGES[COMPANY_MANAGEMENT_PAGE]}>
          <CompanyManagementOverview />
        </Route>
        <Route>
          <RedirectToDefaultPage
            access={{
              companyManagement: hasCompanyManagementAccess,
              userManagement: hasUserManagementAccess,
            }}
          />
        </Route>
      </Switch>
    </>
  ) : (
    <LoginWithRedirect />
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={auth0Options.domain}
    clientId={auth0Options.client_id}
    redirectUri={auth0Options.redirect_uri}
    audience={auth0Options.audience}
    useRefreshTokens
  >
    <BrowserRouter>
      <RootComponent>
        <IsAuthenticated fallback={<LoginWithRedirect />}>
          {({ user, accessToken }) => <App user={user} token={accessToken} />}
        </IsAuthenticated>
      </RootComponent>
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById('root')
)
