import * as React from 'react'

import classnames from 'classnames'
import { Subscription } from 'rxjs'

import { createResizeObservable } from '../../../../utils/createResizeObservable'
import { memoize } from '../../../../utils/memoize'
import { None, none, StrictNull, StrictUndefined } from '../../../../utils/strictNull'
import { TestIds } from '../../../../utils/testIds/testIds'

import { createScrollPositionObservable } from './createScrollPositionObservable'
import { getVisibleItems } from './getVisibleItems'
import { getItemPositions } from './ItemPosition'
import styles, { Styles } from './VirtualTable.module.scss'

type RenderFunction = (index: number, top: number) => React.ReactNode

export type VirtualTableTheme = Partial<Styles>

type VirtualTableProps = Readonly<{
  theme?: VirtualTableTheme
  headerHeight: number
  leftWidth: number
  minRightWidth?: number
  numberOfItems: number

  // Number of extra buffer items to render above/below
  // the visible items. Tweaking this can help reduce
  // scroll flickering on certain browsers/devices.
  overscanCount: number

  // Item index to scroll to (by forcefully scrolling if necessary) x
  scrollToIndex: number | None

  itemHeight: (index: number) => number

  header?: React.ReactNode
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  renderItemBodyLeft?: RenderFunction
  renderItemBodyRight: RenderFunction

  onScroll?: (scrollTop: number) => void
}>

type VirtualTableState = Readonly<{
  bodyRightClientHeight: number | None
  bodyRightClientWidth: number | None
  bodyRightScrollTop: number | None
  scrolledToIndex: number | None
}>

export class VirtualTable extends React.PureComponent<VirtualTableProps, VirtualTableState> {
  private bodyRightRef: HTMLDivElement | null = null

  private bodyLeftRef: HTMLDivElement | null = null

  private headerRightRef: HTMLDivElement | null = null

  private bodyRightMeasureSubscription = new Subscription()

  private getItemPositions = memoize(getItemPositions)

  private getVisibleItems = memoize(getVisibleItems)

  constructor(props: VirtualTableProps) {
    super(props)
    this.state = {
      bodyRightScrollTop: none,
      bodyRightClientHeight: none,
      bodyRightClientWidth: none,
      scrolledToIndex: none,
    }
  }

  public render() {
    const { props, state } = this
    const theme = props.theme || {}
    const itemPositions = this.getItemPositions(props.numberOfItems, props.itemHeight)
    const lastItem = itemPositions[props.numberOfItems - 1] || { index: -1, height: 0, offsetTop: 0 }
    const clientHeight = StrictNull.orElse(state.bodyRightClientHeight, 0)
    const clientWidth = StrictNull.orElse(state.bodyRightClientWidth, 0)

    // In order to show a horizontal scrollbar, we need at least one pixel height:
    const totalHeight = Math.max(lastItem.offsetTop + lastItem.height, 1)

    const visibleItems =
      state.bodyRightClientHeight !== none && state.bodyRightScrollTop !== none
        ? this.getVisibleItems(
            state.bodyRightScrollTop,
            state.bodyRightClientHeight,
            props.overscanCount,
            itemPositions
          )
        : []
    return (
      <div className={classnames(styles.virtualTable, theme.virtualTable)} data-test-id={TestIds.PortcallsTable}>
        <div className={classnames(styles.header, theme.header)} style={{ height: props.headerHeight }}>
          <div
            className={classnames(styles.headerLeft, theme.headerLeft)}
            style={{ height: props.headerHeight, width: props.leftWidth }}
          >
            {props.headerLeft}
          </div>
          <div
            ref={this.setHeaderRightRef}
            onWheel={this.handleWheelHeaderRight}
            className={classnames(styles.headerRight, theme.headerRight)}
            style={{ height: props.headerHeight, width: clientWidth, left: props.leftWidth }}
          >
            <div className={styles.innerScroll} style={{ minWidth: props.minRightWidth }}>
              {props.headerRight}
            </div>
          </div>
          {props.header}
        </div>
        <div
          ref={this.setBodyLeftRef}
          onWheel={this.handleWheelBodyLeft}
          className={classnames(styles.bodyLeft, theme.bodyLeft)}
          style={{ height: clientHeight, width: props.leftWidth, top: props.headerHeight }}
        >
          <div className={classnames(styles.innerScroll, theme.innerScroll)} style={{ height: totalHeight }}>
            {StrictUndefined.fold(
              props.renderItemBodyLeft,
              renderItemBodyLeft =>
                visibleItems.map(visibleItem => renderItemBodyLeft(visibleItem.index, visibleItem.offsetTop)),
              null
            )}
          </div>
        </div>
        <div
          ref={this.setBodyRightRef}
          className={classnames(styles.bodyRight, theme.bodyRight)}
          style={{ left: props.leftWidth, top: props.headerHeight }}
        >
          <div
            className={classnames(styles.innerScroll, theme.innerScroll)}
            style={{ height: totalHeight, minWidth: props.minRightWidth }}
          >
            {visibleItems.map(visibleItem => props.renderItemBodyRight(visibleItem.index, visibleItem.offsetTop))}
          </div>
        </div>
      </div>
    )
  }

  public componentWillUnmount() {
    this.bodyRightMeasureSubscription.unsubscribe()
  }

  public componentDidUpdate() {
    this.updateScrollPositionBasedOnScrollToIndex(this.state.bodyRightScrollTop, this.state.bodyRightClientHeight)
  }

  public componentDidMount() {
    this.updateScrollPositionBasedOnScrollToIndex(this.state.bodyRightScrollTop, this.state.bodyRightClientHeight)
  }

  private updateScrollPositionBasedOnScrollToIndex(scrollTop: number | None, clientHeight: number | None) {
    // If nothing changed, we don't have to act:
    if (this.state.scrolledToIndex === this.props.scrollToIndex) {
      return
    }

    // If the `scrollToIndex` changed to `none`
    // no scrolling is needed. We do need to update
    // the state to reflect that the change was
    // processed:
    if (this.props.scrollToIndex === none) {
      this.setState({ scrolledToIndex: none })
      return
    }

    // If we don't have enough information to determine the desired
    // scroll position, or the element that we need to scroll is missing
    // we short-circuit and return early:
    if (scrollTop === none || clientHeight === none || this.bodyRightRef === null) {
      return
    }

    const itemPositions = this.getItemPositions(this.props.numberOfItems, this.props.itemHeight)
    const itemPosition = itemPositions[this.props.scrollToIndex]
    if (itemPosition === undefined) {
      return
    }

    if (itemPosition.offsetTop < scrollTop) {
      this.bodyRightRef.scrollTop = itemPosition.offsetTop
    }

    if (itemPosition.offsetBottom > scrollTop + clientHeight) {
      this.bodyRightRef.scrollTop = itemPosition.offsetBottom - clientHeight
    }

    this.setState({ scrolledToIndex: this.props.scrollToIndex })
  }

  private setBodyLeftRef = (bodyLeftRef: HTMLDivElement | null): void => {
    this.bodyLeftRef = bodyLeftRef
  }

  private setHeaderRightRef = (headerRightRef: HTMLDivElement | null): void => {
    this.headerRightRef = headerRightRef
  }

  private setBodyRightRef = (bodyRightRef: HTMLDivElement | null): void => {
    // When this function is called, there are two options:
    // * The `<div />` on the right was first rendered (`rightRef !== null`)
    // * The `<div />` on the right was destroyed (`rightRef === null`)
    this.bodyRightRef = bodyRightRef

    // We're starting out with unsubscribing the current
    // event listeners, so whatever the scenario, we can
    // start fresh.
    this.bodyRightMeasureSubscription.unsubscribe()
    this.bodyRightMeasureSubscription = new Subscription()

    // If we're in the process of creating the `<div />` we wish
    // to observe its scroll position and its height.
    if (bodyRightRef !== null) {
      // Listen to changes in scroll position. Update state, and
      // update the scroll position of the elements that should scroll
      // synchronously with `rightRef`.
      const scrollPositionObservable = createScrollPositionObservable(bodyRightRef)
      const scrollPositionSubscription = scrollPositionObservable.subscribe(({ scrollTop, scrollLeft }) => {
        if (this.state.bodyRightScrollTop !== scrollTop) {
          if (this.props.onScroll !== undefined) {
            this.props.onScroll(scrollTop)
          }
          this.setState({ bodyRightScrollTop: scrollTop })
        }
        if (this.bodyLeftRef !== null && this.bodyLeftRef.scrollTop !== scrollTop) {
          this.bodyLeftRef.scrollTop = scrollTop
        }
        if (this.headerRightRef !== null && this.headerRightRef.scrollLeft !== scrollLeft) {
          this.headerRightRef.scrollLeft = scrollLeft
        }
      })
      this.bodyRightMeasureSubscription.add(scrollPositionSubscription)

      // Listen to changes in height. Update state.
      const clientSizeObservable = createResizeObservable(bodyRightRef)
      const clientSizeSubscription = clientSizeObservable.subscribe(({ target }) => {
        if (this.state.bodyRightClientHeight !== target.clientHeight) {
          this.setState({ bodyRightClientHeight: target.clientHeight })
        }
        if (this.state.bodyRightClientWidth !== target.clientWidth) {
          this.setState({ bodyRightClientWidth: target.clientWidth })
        }
      })
      this.bodyRightMeasureSubscription.add(clientSizeSubscription)

      this.updateScrollPositionBasedOnScrollToIndex(bodyRightRef.scrollTop, bodyRightRef.clientHeight)
    }
  }

  private handleWheelHeaderRight = (e: React.WheelEvent<HTMLDivElement>) => {
    if (this.bodyRightRef !== null) {
      this.bodyRightRef.scrollLeft += e.deltaX
    }
  }

  private handleWheelBodyLeft = (e: React.WheelEvent<HTMLDivElement>) => {
    if (this.bodyRightRef !== null) {
      this.bodyRightRef.scrollTop += e.deltaY
    }
  }
}
