import * as React from 'react'

import { Link } from 'react-router-dom'

import { Page, PAGES } from '../../constants/pages'

import styling from './SideMenuItem.module.scss'

type SideMenuItemProps = Readonly<{
  children: React.ReactNode
  onClick?: () => void
}>

export type SideMenuItemInternalProps = SideMenuItemProps &
  Readonly<{
    page: typeof PAGES[Page]
  }>

export type SideMenuItemButtonProps = SideMenuItemProps &
  Readonly<{
    onClick: () => void
  }>

export type SideMenuItemExternalProps = SideMenuItemProps &
  Readonly<{
    url: string
  }>

export const SideMenuItemInternal = (props: SideMenuItemInternalProps) => (
  <Link className={styling.sideMenuItem} to={props.page} onClick={props.onClick}>
    <div className={styling.sideMenuItemWrapper}>
      <SideMenuItemText>{props.children}</SideMenuItemText>
      <SideMenuItemInternalIcon />
    </div>
  </Link>
)

export const SideMenuItemButton = (props: SideMenuItemButtonProps) => (
  <button className={styling.sideMenuItem} onClick={props.onClick}>
    <div className={styling.sideMenuItemWrapper}>
      <SideMenuItemText>{props.children}</SideMenuItemText>
      <SideMenuItemInternalIcon />
    </div>
  </button>
)

export const SideMenuItemExternal = (props: SideMenuItemExternalProps) => (
  <a
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    className={styling.sideMenuItem}
    onClick={props.onClick}
  >
    <div className={styling.sideMenuItemWrapper}>
      <SideMenuItemText>{props.children}</SideMenuItemText>
      <SideMenuItemExternalIcon />
    </div>
  </a>
)

const SideMenuItemText = (props: { children: React.ReactNode }) => (
  <span className={styling.sideMenuItemText}>{props.children}</span>
)

const SideMenuItemInternalIcon = () => <span className={styling.sideMenuItemInternalIcon} />
const SideMenuItemExternalIcon = () => <span className={styling.sideMenuItemExternalIcon} />
